import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import Cookies from "js-cookie";
import jsQR from "jsqr";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  Container,
  InputBase,
} from "@mui/material";
import { Spinner } from "flowbite-react";

function Payment() {
  const navigate = useNavigate();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [angpao, setLinkAngpao] = useState("");
  const [dataWebhook, setdataWebhook] = useState("");
  const [amount, setAmount] = useState("");
  const [Cresponse, setTurnstileToken] = useState();

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [SlipImg, setSlipImg] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const t = queryParams.get("t");

  useEffect(() => {
    if (t) {
      setSelectedOption(t);
    }
  }, [t]); // Only re-run the effect if 't' changes

  const [selectedOption, setSelectedOption] = useState(""); // Added this line
  const handleSelectChange = (event) => setSelectedOption(event.target.value); // Added this line

  const [user, setUser] = useState({});
  const fetchData = async () => {
    localStorage.setItem("loading", "true");
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem("loading", "false");
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem("loading", "false");
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isValidAngpaoLink = (link) => {
    const linkPattern = /^https?:\/\/gift\.truemoney\.com\/campaign\/\?v=.+$/;
    return linkPattern.test(link);
  };

  const LinkAngpao = async () => {
    if (!isValidAngpaoLink(angpao)) {
      return toast.error("กรุณากรอกลิงค์ให้ถูกต้อง");
    }
    const requestData = { angpao, Cresponse };
    setLoadingBTN(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}angpao`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(requestData),
      }
    );

    const data = await response.json();
    if (data.status === 200) {
      setLoadingBTN(false);
      fetchData();
      setLinkAngpao("");
      toast.success(data.message);
    } else {
      setLoadingBTN(false);
      toast.error(data.message);
    }
  };

  const TrueWalletWebhook = async () => {
    if (!amount) {
      return toast.error("กรุณากรอกจำนวนให้ถูกต้อง");
    }
    if (amount < 10) {
      return toast.error("ขั้นต่ำการชำระเงิน 10 บาท");
    }
    const requestData = { amount, Cresponse, step: "create" };
    setLoadingBTN(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}truewalletWebhook`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(requestData),
      }
    );

    const data = await response.json();
    if (data.status === 200) {
      setdataWebhook(data.data);
      setTimeout(() => {
        navigate(
          `/app/profile/payment/callback/truewallet-p2p/${data.data.payment_id}`
        );
      }, 1500);
    } else {
      setLoadingBTN(false);
      toast.error(data.message);
    }
  };

  const scanCode = (file) => {
    try {
      const imageUrl = URL.createObjectURL(file);
      const image = new Image();
      image.src = imageUrl;
      setSlipImg(image.src);

      image.onload = async () => {
        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");

        canvas.width = image.width;
        canvas.height = image.height;
        canvasContext.drawImage(image, 0, 0, canvas.width, canvas.height);

        const imageData = canvasContext.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          if (!code.data) {
            setLoadingBTN(false);
            return toast.error("ไม่พบรหัส QR #1");
          }
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_ENDPOINT}slip`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${hzcToken}`,
                },
                body: JSON.stringify({ qrCode: code.data, Cresponse }),
              }
            );
            const responseData = await response.json();
            if (responseData.status === 200) {
              setLoadingBTN(false);
              toast.success(responseData.message);
            } else if (responseData.status === 400) {
              setLoadingBTN(false);
              toast.error(responseData.message);
            } else {
              setLoadingBTN(false);
              toast.error(responseData.message);
            }
          } catch (error) {
            setLoadingBTN(false);
            toast.error("Error sending data to the API: " + error);
          }
        } else {
          setLoadingBTN(false);
          toast.error("ไม่พบรหัส QR #2");
        }
      };
    } catch (err) {
      console.error(err);
      setLoadingBTN(false);
      toast.error("เกิดข้อผิดพลาดในการอ่านคิวอาร์โค้ด");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      scanCode(file);
      setLoadingBTN(true);
    }
  };

  const Coupon = async () => {
    if (!amount) {
      return toast.error("กรุณากรอกจำนวนเงิน");
    }
    const requestData = { code: amount, Cresponse };
    setLoadingBTN(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}coupon`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(requestData),
      }
    );

    const data = await response.json();
    if (data.status === 200) {
      setLoadingBTN(false);
      fetchData();
      setAmount("");
      toast.success(data.message);
    } else {
      setLoadingBTN(false);
      toast.error(data.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Payment</title>
      </Helmet>
      <Container maxWidth="lg" className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
          <Box mt={12} p={6} boxShadow={5} bgcolor="background.paper" borderRadius={4}>
            <Box className="flex items-center space-x-4">
              <Box>
                <h2 className="text-lg font-semibold">เติมเคดิต</h2>
                <h1 className="text-2xl font-bold">
                  ยินดีตอนรับ {user.acc_username} จ้าาาาาา
                </h1>
                <h6 className="mb-0">
                  ยินดีตอนรับ : {user.acc_mobile}
                </h6>
                <p className="mb-0">
                  <i className="bi bi-wallet2"></i>{" "}
                  <span>ยอดเงินคงเหลือ : {user.acc_credit} บาท</span>
                </p>
                <p className="mb-0">
                  <i className="bi bi-piggy-bank-fill"></i>{" "}
                  <span>
                    เคดิตเงินคืนคงเหลือ : {user.acc_point} HCG
                  </span>
                </p>
              </Box>
            </Box>
            <Box mt={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small"  fullWidth>
                <InputLabel id="payment-method-label">
                  เลือกวิธีการชำระเงิน
                </InputLabel>
                <Select
                  labelId="payment-method-label"
                  id="payment-method"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  label="Payment method"
                >
                  <MenuItem value="angpao">ซองของขวัญ</MenuItem>
                  <MenuItem value="truewallet">ทรูวอลเล็ต QR</MenuItem>
                  <MenuItem value="internet-banking">Internet banking</MenuItem>
                  <MenuItem value="coupon">คูปอง</MenuItem>
                </Select>
              </FormControl>
            </Box>
            
            {selectedOption === "angpao" && (
              <>
                <Box id="angpao">
                  <Box fullWidth mt={2}>
                    <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gradient-to-br">
                      เติมเงินด้วยซองของขวัญ!
                    </h5>
                    <Box className="mb-2">
                      <label
                        for="default-input"
                        className="block mb-2 text-sm font-medium text-gradient-to-br"
                      >
                        ลิงค์อังเปา
                      </label>
                      <TextField
                        type="text"
                        value={angpao}
                        onChange={(e) => setLinkAngpao(e.target.value)}
                        placeholder="https://gift.truemoney.com/campaign/?v=xxxxxxxxxxxx"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Button
                      variant="contained"
                      className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                      onClick={LinkAngpao}
                      disabled={loadingBTN}
                    >
                      <i className="bi bi-box-arrow-up-right"></i> เติมเงิน
                    </Button>
                    <span className="text-yellow-400" mt={2}>
                      <del>มีค่าธรรมเนียม 2.9%</del> (เติมเกมสิ
                      ออกค่าธรรมเนียมให้แล้ววว ^^)
                    </span>
                  </Box>
                </Box>
              </>
            )}
            {selectedOption === "truewallet" && (
              <>
                <Box id="wallet_webhook">
                  <Box fullWidth mt={2}>
                    <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gradient-to-br">
                      เติมเงินด้วยทรูวอลเล็ต QR Payment!
                    </h5>
                    <h1 className="mb-2 text-sm font-semibold tracking-tight text-red-400">
                      กรุณาใช้เบอร์ที่ลงทะเบียน {user.acc_mobile}{" "}
                      ในการทำรายการเพื่อป้องกันการฉอโกง
                    </h1>

                    {!dataWebhook && (
                      <>
                        <Box className="mb-2">
                          <label
                            htmlFor="default-input"
                            className="block mb-2 text-sm font-medium text-gradient-to-br"
                          >
                            จำนวนเงิน
                          </label>
                          <TextField
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="ขั่นต่ำ 10 บาท"
                            variant="outlined"
                            fullWidth
                          />
                        </Box>

                        <Box
                          className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-purple-800 dark:text-red-400"
                          role="alert"
                        >
                          <svg
                            className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className="sr-only">Danger</span>
                          <Box>
                            <span className="font-medium">เพิ่มเติม:</span>
                            <ul className="mt-1.5 list-disc list-inside">
                              <li>
                                เหตุผลที่เราต้องใช้ <b>เฉพาะเบอร์ที่ลงทะเบียน</b>{" "}
                                เผื่อป้องกันการฉอโกง
                              </li>
                            </ul>
                          </Box>
                        </Box>

                        <Button
                          variant="contained"
                          className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                          onClick={TrueWalletWebhook}
                        >
                         <><i className="bi bi-box-arrow-up-right"></i>{" "} เติมเงิน</>
                        </Button>
                        <span className="text-yellow-400">
                          <del>มีค่าธรรมเนียม 2.9%</del> (เติมเกมสิ
                          ออกค่าธรรมเนียมให้แล้ววว ^^)
                        </span>
                      </>
                    )}
                    {dataWebhook && (
                      <>
                        <Box role="status">
                          <svg
                            aria-hidden="true"
                            className="mt-4 inline w-8 h-8 text-gradient-to-br animate-spin dark:text-gradient-to-br fill-purple-600 dark:fill-purple-300"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
            {selectedOption === "coupon" && ( // Added this line
              <>
                <Box id="coupon">
                <Box fullWidth mt={2}>
                    {/* <img src="/coupon.png" alt="coupon" className="w-9 h-9" /> */}
                    <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gradient-to-br">
                      เติมเงินด้วยคูปอง!
                    </h5>
                    <Box className="mb-2">
                      <label
                        for="default-input"
                        className="block mb-2 text-sm font-medium text-gradient-to-br"
                      >
                        โค้ดคูปอง
                      </label>
                      <input
                        type="text"
                        id="default-input"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="HWYCG-XXXX-XXXX-XXXX"
                        className="bg-purple-50 border border-purple-300 text-gradient-to-br text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                      />
                    </Box>
                    <Button
                      variant="contained"
                      className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                      disabled={loadingBTN}
                      onClick={Coupon}
                    >
                       <>
                          <i className="bi bi-box-arrow-up-right"></i> เติมเงิน
                        </>
                    </Button>
                    <span className="label-text-alt">
                      <del>มีค่าธรรมเนียม</del> (เพียงแค่รวมกิจกรรมกับทาง เติมเกมสิ ก็ได้โค้ดมากแล้วว ^^)
                    </span>
                  </Box>
                </Box>
              </>
            )}
            {selectedOption === "internet-banking" && ( // Added this line
              <>
                <Box id="slip">
                  <Box fullWidth mt={2}>
                    <h3 className="font-bold text-lg">เติมเงินด้วยสลิปโอนเงิน</h3>
                    <Box className="grid grid-cols-2 gap-2">
                      <Box>
                        <img
                          src="/assets/kasikorn.png"
                          alt="QR Code"
                          className="w-24 mx-auto mt-3"
                        />
                        <h1 className="text-2xl text-center mt-3">
                          ธนาคารกสิกรไทย
                        </h1>
                        <h1 className="text-1xl text-center">ธีรภัทร์ ถาวัง</h1>
                        <h1 className="text-1xl text-center">126-3-71882-4</h1>
                      </Box>
                      <Box>
                        <img
                          src="/assets/kasikorn.png"
                          alt="QR Code"
                          className="w-24 mx-auto mt-3"
                        />
                        <h1 className="text-2xl text-center mt-3">
                          ธนาคารกสิกรไทย
                        </h1>
                        <h1 className="text-1xl text-center">นภสร พูลสวัสดิ์</h1>
                        <h1 className="text-1xl text-center">126-2-79979-0</h1>
                      </Box>
                    </Box>
                    <label className="form-control w-full mt-3">
                      {loadingBTN ? (
                        <>
                          <Spinner color="purple" size="md" />
                          <Box mt={2}>
                            <img
                              src={SlipImg} alt={SlipImg}
                              className="file-upload"
                              style={{
                                width: 200,
                                display: "block",
                                margin: "0 auto",
                                marginTop: 8,
                              }}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <label
                            className="block mb-2 text-sm font-medium text-white"
                            for="multiple_files"
                          >
                            โปรดอัพไฟล์สลิปโอนเงิน
                          </label>
                          <div class="custom-file-upload">
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                          <label for="fileInput">
                            <i class="fas fa-cloud-upload-alt"></i> เลือกรูปภาพสลิป
                          </label>
                        </div>
                        </>
                      )}
                      <Box className="label font-bold text-sm">
                        <span className="label-text-alt">
                          หากเกิน 10 นาทีหลังจากโอนจะไม่สามารถเติมได้{" "}
                        </span>
                        <span className="label-text-alt">
                          ! โปรดตรวจสอบสลิปก่อนทำรายการ
                        </span>
                      </Box>
                    </label>

                    <Box
                      className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 "
                      role="alert"
                    >
                      <i className="bi bi-question-circle-fill flex-shrink-0 inline w-4 h-4 me-3"></i>
                      <Box>
                        <span className="font-medium">หมายเหตุ :</span>
                        <ul className="mt-1.5 list-disc list-inside">
                          <li>
                            <b>คำเตือน</b> หากการชำระเงินสำเร็จแล้ว จะ
                            ไม่สามารถขอเงินคืนได้ไม่ว่ากรณีใดๆก็ตาม
                            ไม่สามารถแก้ไขหรือยกเลิกรายการได้
                          </li>
                          <li>โปรดหลีกเลี่ยงการโอนเงินช่วงเวลา 22.30 - 03.00 น.{" "} <b>(ป้องกันความผิดพลาด)</b></li>
                          <li>
                            เหตุผลที่เราต้องทำรายการ <b>ภายใน 10 นาที</b>{" "}
                            เพื่อป้องกันการทำ<b>รายการผิดพลาด</b>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
        </Box>
      </Container>

      <Turnstile
        siteKey={process.env.REACT_APP_SITEKEY}
        options={{
          action: "submit-form",
          theme: "auto",
          size: "invisible",
        }}
        onSuccess={setTurnstileToken}
      />
    </>
  );
}

export default Payment;
