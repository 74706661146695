import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import { CircularProgress, Typography, Button, TextField, Select, MenuItem, Grid, Paper, Box } from "@mui/material";

function ApplicationsDevEdit() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [rows, setRows] = useState({
    applications_name: "",
    applications_details: "",
    applications_hostname: "",
    applications_whitelistIP: "",
    applications_active: "" // default value
  });
  const { id } = useParams();

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 403 || data.status === 401) {
        setLoading(false)
       localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTracking();
  }, []);

  const fetchTracking = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}infoApplications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify({ code: id }),
      });
      const data = await response.json();
      if (data.status === 200) {
        setLoading(false)
       localStorage.setItem('loading', 'false');
        setRows(data.data);
      } else {
        navigate('/app/applications/dev/')
        setLoading(false)
       localStorage.setItem('loading', 'false');
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRows((prevRows) => ({
      ...prevRows,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}edit_application`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(rows),
      });

      const data = await response.json();
      if (response.ok) {
        setLoading(false)
        toast.success("Application edited successfully");
        navigate(`/app/applications/dev/my/${id}`);
      } else {
        setLoading(false)
        toast.error(data.message || "Failed to edit application");
      }
    } catch (error) {
      setLoading(false)
      console.error("Error editing application:", error);
      toast.error("Failed to edit application");
    }
  };

  return (
    <>
      <Helmet>
      <title>เติมเกมสิ | GAME's API Edit</title>
      </Helmet>
        <Grid container justifyContent="center" alignItems="center" minHeight="100vh">
          <Grid item xs={12} md={8} lg={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>GAME's API Edit</Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 3 }}>แก้ไข เกม API</Typography>
              <Button variant="contained" onClick={() => navigate(`/app/applications/dev/my/${id}`)} sx={{ mb: 2 }}>ย้อนกลับ</Button>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="GAME's API Name"
                  variant="outlined"
                  name="applications_name"
                  value={rows.applications_name}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                  required
                  placeholder="ชื่อแอพคุณชื่ออะไรละ ?"
                />
                <TextField
                  fullWidth
                  label="GAME's API Details"
                  variant="outlined"
                  name="applications_details"
                  value={rows.applications_details}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                  required
                  placeholder="ลองบอกรายละเอียดของแอพสักนิดสิ"
                />
                <TextField
                  fullWidth
                  label="GAME's API Domain"
                  variant="outlined"
                  name="applications_hostname"
                  value={rows.applications_hostname}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                  required
                  placeholder="โดมเมนของคุณอะไร localhost หรือป่าว"
                />
                <TextField
                  fullWidth
                  label="GAME's API whitelist IP"
                  variant="outlined"
                  name="applications_whitelistIP"
                  value={rows.applications_whitelistIP}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                  required
                  placeholder="ไม่อยากจำกัด IP ก็แค่ใช้ * แต่ถ้า IP เยอะก็แค่ใช้ (,)"
                />
                <Typography variant="body2" sx={{ mb: 2 }}>EX. 192.168.1.2 , 185.2.5.22</Typography>
                <Select
                  fullWidth
                  label="GAME's API Status"
                  variant="outlined"
                  name="applications_active"
                  value={rows.applications_active}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="true">เปิดใช้งาน</MenuItem>
                  <MenuItem value="false">ปิดการใช้งานชั่วคราว</MenuItem>
                </Select>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                 
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    <>ดำเนินการแก้ไข</>
                  )}
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
    </>
  );
}

export default ApplicationsDevEdit;
