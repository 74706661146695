import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

function Transactions() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [user, setUser] = useState({});
  const [rows, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTransactions = async () => {
    localStorage.setItem('loading', 'true');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}getTransactions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setTransactions(data.data);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTransactions();
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Transactions</title>
      </Helmet>

      <Container maxWidth="lg" className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
      <Box mt={12} p={3} boxShadow={5} bgcolor="background.paper" borderRadius={4}>
          <h2 className="text-lg font-semibold p-2">ประวัติการทำธุรกรรม</h2>
          <div className="flex items-center space-x-4">
            <div>
              <h1 className="text-2xl font-bold">{user.acc_mobile}</h1>
              <p className="text-purple-400">เป็นสมาชิกเมื่อ : {user.acc_date}</p>
            </div>
          </div>


{rows !== null && rows.length > 0 ? (
            <>
          <TableContainer >
            <Table sx={{ minWidth: 650 }} >
              <TableHead>
                <TableRow>
                  <TableCell>ช่องทางการชำระเงิน</TableCell>
                  <TableCell>สถานะ</TableCell>
                  <TableCell>ยอดชำระ</TableCell>
                  <TableCell>วันที่</TableCell>
                  <TableCell>รายละเอียด</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.type === 'angpao' ? 'Angpao' : 
                        row.type === 'wallet_webhook' ? 'Wallet Webhook' :
                        row.type === 'credit_debit_card' ? 'Credit Debit Card' :
                        row.type === 'slip' ? 'Slip' :
                        row.type === 'coupon' ? 'Coupon' : 'Unknown Type'}
                    </TableCell>

                    <TableCell>
                      <span className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded text-gray-700 ${
                        row.status === 'approve' ? 'bg-gray-700 text-green-300 border border-green-300' :
                        row.status === 'wait' ? 'bg-gray-700 text-yellow-300 border border-yellow-300' :
                        row.status === 'expire' || row.status === 'cancel' ? 'bg-gray-700 text-red-300 border border-red-300' : ''
                      }`}>
                        {row.status === 'approve' ? 'approve' :
                        row.status === 'wait' ? 'wait' :
                        row.status}
                      </span>
                    </TableCell>

                    <TableCell>{parseFloat(row.amount).toFixed(2)}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (row.status === 'wait' && row.type === 'wallet_webhook') {
                            navigate(`/app/profile/payment/callback/truewallet-p2p/${row.payment_id}`);
                          } else {
                            navigate(`/app/profile/payment/callback/details/${row.payment_id}`);
                          }
                        }}
                        disabled={loading}
                        sx={{ mt: 3 }}
                      >
                        {row.status === 'wait' && row.type === 'wallet_webhook' ? (
                          <>
                            <i className="bi bi-arrow-right-circle"></i>&nbsp;ทำรายการต่อ
                          </>
                        ) : (
                          <>
                            <i className="bi bi-search"></i>&nbsp;รายละเอียด
                          </>
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
           </>
            ) : (
                <div className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center mt-12">
                        <h1 className="text-purple-600 dark:text-purple-200">ไม่พบรายการ</h1>      
                  </div>   
                </div>
            )}
        </Box>
      </Container>
    </>
  );
}

export default Transactions;
