import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";

function Setting() {
  const navigate = useNavigate();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setUser(data.user);
      } else {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const editPassword = async () => {
    setLoading(true);
    const requestData = { oldPassword, newPassword, newPasswordConfirm };

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}change-password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(requestData),
      }
    );

    const data = await response.json();
    if (data.status === 200) {
      setOldPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
      localStorage.setItem('loading', 'false');
      fetchData();
      toast.success(data.message);
    } else {
      localStorage.setItem('loading', 'false');
      toast.error(data.message);
    }
  };

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.setItem('loading', 'false');
      Cookies.remove("HNAWNYToken");
      toast.success("Successfully logged out");
      navigate(`/auth/login`);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Setting</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box
          mt={12}
          p={4}
          boxShadow={5}
          bgcolor="background.paper"
          borderRadius={4}
        >
          <Typography variant="h4" align="center" gutterBottom>
            ตั้งค่า
          </Typography>
          <Box my={4}>
            <Typography variant="h5" align="center">
              {user.acc_mobile}
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              เป็นสมาชิกเมื่อ: {user.acc_date}
            </Typography>
          </Box>

          <Divider />

          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              เปลี่ยนรหัสผ่าน
            </Typography>
            <Typography variant="caption">รหัสผ่านเก่า</Typography>
            <TextField
              type="password"
              fullWidth
              variant="outlined"
              margin="normal"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <Typography variant="caption">รหัสผ่านใหม่</Typography>
            <TextField
              type="password"
              fullWidth
              variant="outlined"
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Typography variant="caption">ยืนยันรหัสผ่านใหม่</Typography>
            <TextField
              type="password"
              fullWidth
              variant="outlined"
              margin="normal"
              value={newPasswordConfirm}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={editPassword}
              disabled={loading}
              sx={{ mt: 3 }}
            >
              <><i className="bi bi-shield-lock"></i>&nbsp; เปลี่ยนรหัสผ่าน</>
            </Button>
          </Box>

          <Divider />

          <Box mt={4}>
            <Typography variant="h5" align="center" gutterBottom>
              เพิ่มเติม
            </Typography>
            <Box mt={2}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate("/help")}
                sx={{ mb: 2 }}
              >
                <i className="bi bi-person-raised-hand"></i>&nbsp;ติดต่อช่วยศูนย์ช่วยเหลือ
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={logout}
                disabled={loading}
                sx={{
                  bgcolor: "#f2383b",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#d42b2e",
                  },
                }}
              >
                <><i class="bi bi-door-closed"></i>&nbsp; ออกจากระบบ</>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Setting;
