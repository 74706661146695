import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import TextField from '@mui/material/TextField';

const CashcardTopup = () => {
  const navigate = useNavigate();
  const [card, setCashCard] = useState([]);
  const [MobileTopup, setMobileTopup] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);


  const getCashCard = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}getCashCard`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setCashCard(data.data);
      } else {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getCashCard();
  }, []);

  useEffect(() => {
    const results = card.filter(item =>
      item.company_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
  }, [searchQuery, card, MobileTopup]);

  return (
    <>
    <Helmet>
        <title>เติมเกมสิ | Cashcard Topup</title>
      </Helmet>
        <>
          <div className="min-h-screen container mx-auto px-4 mt-28" data-aos="zoom-out-up">
            <div class="flex place-items-center space-x-3 mt-12" id="card">
              <p class="mb-3 text-xl font-bold text-gray-100">บัตรเงินสด</p>
            </div>
            <div class="mb-6">
              <label
                for="default-input"
                class="block mb-2 text-sm font-medium text-white"
              >
                ค้นหาบัตรเงินสดที่ต้องการ
              </label>
              <TextField
                type="text"
                variant="outlined"
                placeholder="อยากซื้อบัตรเงินสดอะไร หาเลย"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </div>
            <div class="grid grid-cols-2 md:grid-cols-6 gap-y-1 container mx-auto">
            {searchResults.length > 0 ? (
                searchResults.map((cardItem) => (
                    <div
                    className={`gamer`}
                    title={cardItem.company_id}
                    key={cardItem.company_id}
                    onClick={() => navigate(`/shop/cashcard/${cardItem.company_id}`)}
                    >
                    <div class="px-5 pb-5 mt-5 bg-opacity-80">
                        <div class="flex items-center justify-between mt-5">
                        <div class="relative overflow-hidden rounded-md aspect-prepaid-card">
                            <div class="z-[1] font-medium absolute top-0 right-0 bg-main-400 rounded-tl-md rounded-br-md">
                            {/* <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                                ส่วนลด : 0 %
                            </p> */}
                            </div>
                            <div class="z-[1] font-medium absolute bottom-0 left-0 bg-main-400 rounded-tl-md rounded-br-md">
                            <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                                {cardItem.company_name}
                            </p>
                            </div>
                            <img
                            src={`/assets/cashcard/product/${cardItem.company_id}.png`}
                            class="w-full object-cover h-full"
                            alt={cardItem.company_name}
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                ))
                ) : (
                <div className="text-center text-gray-50 text-2xl py-8">
                    <i class="bi bi-emoji-frown-fill"></i> ไม่พบสินค้าที่ต้องการ
                </div>
                )}

            </div>


          </div>
        </>
    </>
  );
};

export default CashcardTopup;
