import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Turnstile } from '@marsidev/react-turnstile'
import Cookies from 'js-cookie';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { Spinner } from "flowbite-react";


function ResetPassword() {
    const hzcToken = Cookies.get("HNAWNYToken");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phone = queryParams.get('phone');  
    const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("" || phone);
  const [otp, setOtp] = useState("");
  const [ref, setRef] = useState("");
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [Cresponse, setTurnstileToken] = useState();

  Cookies.remove('HNAWNYToken');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (showPhoneNumberInput) {
      if (phoneNumber.length === 10) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}resetpassword`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ phoneNumber, step: "1" }),
          });

          const data = await response.json();

          if (data.status === 200) {
            setShowPhoneNumberInput(false);
            setShowOtpInput(true);
            setRef(data.ref);
            setLoading(false);
          } else {
            toast.error(data.message, { autoClose: 5000 });
            setLoading(false);
          }
        } catch (error) {
          console.error('Error sending OTP:', error);
          setLoading(false);
        }
      } else {
        toast.error('โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง', { autoClose: 5000 });
        setLoading(false);
      }
    } else if (showOtpInput) {
      if (otp.length === 6) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}resetpassword`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ otp, ref, phoneNumber, step: "2"  }),
          });

          const data = await response.json();

          if (data.status === 200) {
            setShowOtpInput(false);
            setShowPasswordInput(true);
            setLoading(false);
          } else {
            toast.error(data.message, { autoClose: 5000 });
            setLoading(false);
          }
        } catch (error) {
          console.error('Error verifying OTP:', error);
          setLoading(false);
        }
      } else {
        toast.error('โปรดกรอก OTP ให้ถูกต้อง', { autoClose: 5000 });
        setLoading(false);
      }
    } else if (showPasswordInput) {
        try {
            if(!password || !confirmpassword){
                return toast.error("กรุณากรอกรหัสผ่านให้ครบทุกช่อง");
            }
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}resetpassword`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ Cresponse, newPassword: password, newPasswordConfirm: confirmpassword, phoneNumber, otp, ref, step: "3" }),
            });
  
            const data = await response.json();
  
            if (data.status === 200) {
                setLoading(true);
                toast.success(data.message);
                setTimeout(() => {
                    navigate('/auth/login');
                  }, 1000);     
            } else {
              toast.error(data.message, { autoClose: 5000 });
              setLoading(false);
            }
          } catch (error) {
            console.error('Error verifying OTP:', error);
            setLoading(false);
          }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hzcToken) {
      navigate('/app/profile');
    }
  }, [hzcToken]);

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | ResetPassword</title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center relative mb-16" style={{ top: 0, marginTop: "20px" }}  data-aos="zoom-out-up">
        <form onSubmit={handleSubmit}>
          <div className="mx-auto max-w-xl w-full p-10">
            <h2 className="text-4xl text-gradient-to-br font-semibold font-semibold ">รีเซ็ตรหัสผ่าน</h2>
            <h3 class="self-center font-semibold ">
             Termgame SI <sub class="self-center font-semibold text-gradient-to-br"> By Hnawny Cloud X MUCITY</sub>
            </h3>

            {showPhoneNumberInput && (
              <label className="form-control flex flex-col w-full mt-6">
                <div className="label mb-2">
                  <span className="label-text-alt">
                    เบอร์โทรศัพท์
                  </span>
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="06XXXXXXXX"
                  className="bg-purple-50 border border-purple-300 text-purple-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                  required
                />
              </label>
            )}

            {showOtpInput && (
              <label className="form-control flex flex-col w-full mt-6">
                <div className="label mb-2">
                  <span className="label-text-alt">
                    OTP
                  </span>
                </div>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder={`ยืนยันหมายเลข OTP ( Ref : ${ref}) `}
                  className="bg-purple-50 border border-purple-300 text-purple-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                  required
                />
              </label>
            )}

            {showPasswordInput && (
              // แสดงฟอร์มสร้างรหัสผ่าน
              <>
              <label className="form-control flex flex-col w-full mt-6">
                <div className="label mb-2">
                  <span className="label-text-alt">
                    รหัสผ่าน
                  </span>
                </div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="*****"
                  className="bg-purple-50 border border-purple-300 text-purple-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                  required
                />
              </label>

                <label className="form-control flex flex-col w-full max-w-xs mx-auto mt-6">
                    <div className="label mb-2">
                        <span className="label-text-alt">
                            ยืนยันรหัสผ่าน
                        </span>
                    </div>
                    <input
                        value={confirmpassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        placeholder="*****"
                        className="bg-purple-50 border border-purple-300 text-purple-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                        required
                    />
                </label>
            </>
            )}

            <Turnstile 
                siteKey={process.env.REACT_APP_SITEKEY}
                options={{
                    action: 'submit-form',
                    theme: 'auto',
                    size: 'invisible',
                }} 
                onSuccess={setTurnstileToken}
            />

            <div className="col mt-6 space-y-4">
              <button
                class="w-full text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 focus:ring-4 focus:outline-none focus:ring-pink-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                type="submit"
                disabled={loading}
                style={{ color: "#ffffff" }}
              >
                {loading ? (
                  <><Spinner color="purple" size="md" /></>
                ) : (
                  <><i className="bi bi-arrow-up-right-square-fill"></i> ดำเนินการต่อ</>
                )}
              </button>
              <span
                className="label-text-alt w-full block"
                style={{ color: "#ad001d" }}
              >
                ** หากท่านต้องการกู้บัญชีให้ทำการกรอกเบอร์มือถือแล้วก็ดำเนินการต่อ (เบอร์ไทยเท่านั้น) **
              </span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
