import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import { Spinner } from "flowbite-react";
import Slider from "react-slick";

const Home = () => {
  const navigate = useNavigate();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [game, setGame] = useState([]);
  const [card, setCashCard] = useState([]);
  const [setting, setsetting] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setData(data.user);
      } else if (data.status === 403 || data.status === 401) {
        // toast.error(data.message);
        // navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      // handle fetch error
      console.error("Error fetching data:", error);
    }
  };

  const GetGame = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}getGame`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setGame(data.data);
      } else {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
      }
    } catch (error) {
      // handle fetch error
      console.error("Error fetching data:", error);
    }
  };

  const GetSetting = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}web_setting`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setsetting(data.data);
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
    }
  };

  const getCashCard = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}getCashCard`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setCashCard(data.data);
      } else {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetGame();
    getCashCard();
    GetSetting();
    fetchData();
  }, []);

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <span
        onClick={onClick}
        className="absolute top-1/2 right-4 -translate-y-1/2 inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
      >
        <svg
          class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 9 4-4-4-4"
          />
        </svg>
        <span class="sr-only">Next</span>
      </span>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <span
        onClick={onClick}
        className="absolute top-1/2 left-4 -translate-y-1/2 inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none z-10"
      >
        <svg
          class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 1 1 5l4 4"
          />
        </svg>
        <span class="sr-only">Previous</span>
      </span>
    );
  }

  var settings1 = {
    // dots: true,
    // infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings = {
    autoplay: true,
    speed: 500,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    slidesToShow: 7,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // หน้าจอใหญ่
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600, // หน้าจอเล็ก
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <>
    <Helmet>
        <title>เติมเกมสิ | Home</title>
      </Helmet>
        <>
          <div
            className="min-h-screen container mx-auto px-4"
            // data-aos="zoom-out-up"
          >
            <div
              className="container rounded-lg"
              data-aos="zoom-out-up"
              style={{
                maxWidth: "1920px",
                padding: "6rem 0 0 0",
                position: "relative",
              }}
            >
              <Slider {...settings1}>
                <div>
                  <img
                    alt="1"
                    className="rounded-lg"
                    src={setting.carousel_1}
                  />
                </div>
                <div>
                  <img
                    alt="2"
                    className="rounded-lg"
                    src={setting.carousel_2}
                  />
                </div>
                <div>
                  <img
                    alt="3"
                    className="rounded-lg"
                    src={setting.carousel_3}
                  />
                </div>
              </Slider>
            </div>

            <div className="i-wrapper p-3 mt-5">
              <div class="grid md:grid-cols-2 sm:gap-2 gap-y-5">
                {hzcToken ? (
                  <div className="i-container mr-4">
                    <div style={{ minWidth: "120px" }}>
                      <img
                        src="/assets/TermgameSI.jpeg"
                        alt="Logo_Game"
                        className="p-1 rounded-full ring-2 ring-gray-300 "
                        width={96}
                        height={96}
                      />
                    </div>
                    <div
                      className="i-container"
                      style={{
                        justifyContent: "flex-start",
                        minWidth: "200px",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <h1 className="text-2xl text-gradient-to-br font-bold">
                          ยินดีตอนรับ {data.acc_username} จ้าาาาาา
                        </h1>
                        <h6 className="mb-0">
                          ยินดีตอนรับ : {data.acc_mobile}
                        </h6>
                        <p className="mb-0">
                          <i className="bi bi-wallet2"></i>{" "}
                          <span>ยอดเงินคงเหลือ : {data.acc_credit} บาท</span>
                        </p>
                        <p className="mb-0">
                          <i className="bi bi-piggy-bank-fill"></i>{" "}
                          <span>
                            เคดิตเงินคืนคงเหลือ : {data.acc_point} HCG
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="i-container">
                    <div
                      class="flex p-4 mb-4 text-sm text-red-400 rounded-lg bg-gray-800 border border-red-300 text-red-400 w-full"
                      role="alert"
                    >
                      <svg
                        class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span class="sr-only">Info</span>
                      <div>
                        <span class="font-medium">
                          กรุณาเข้าสู่ระบบก่อนดำเนินรายการต่างๆๆ
                        </span>
                        <ul class="mt-1.5 list-disc list-inside">
                          <li>ใช้แค่เบอร์มือถือสมัคร</li>
                          <li>ช่องทางเติมเคดิตที่มากกมาย</li>
                          <li>ส่วนลดเคดิตเงินคืนที่คืนเยอะสุดๆๆ</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <div className="i-container">
                  <div
                    class="flex p-4 mb-4 text-sm  rounded-lg bg-gray-800 border border-blue-300 text-blue-300"
                    role="alert"
                  >
                    <svg
                      class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Info</span>
                    <div>
                      <span class="font-medium">ตรงนี้มีประกาศนะ :</span>
                      <ul class="mt-1.5 list-disc list-inside">
                        <li>{setting.announce_1}</li>
                        <li>{setting.announce_2}</li>
                        <li>{setting.announce_3}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3 flex items-center justify-between">
              <div>
                <h2 class="mb-3 text-xl font-bold text-gray-50">
                  เติมเกมออนไลน์
                </h2>
                <p class="mb-3 text-sm font-bold text-gray-200">
                  GameOnline Topup
                </p>
              </div>
              <button
                type="button"
                onClick={() => navigate("/shop/termgame")}
                class="text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 focus:ring-4 focus:outline-none focus:ring-pink-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                ดูทั้งหมด
              </button>
            </div>
            <div class="grid grid-cols-2 md:grid-cols-6 gap-y-5 container mx-auto">
              {Array.isArray(game) &&
                game.slice(0, 8).map((gameItem) => (
                  <div
                    className={`gamer`}
                    title={gameItem.company_id}
                    key={gameItem.company_id}
                    onClick={() => navigate(`/shop/termgame/${gameItem.company_id}`)}
                  >
                    <div class="px-5 pb-5 mt-5 bg-opacity-80">
                      <div class="flex items-center justify-between mt-5">
                        <div class="relative overflow-hidden rounded-md aspect-square">
                          {/* <div class="absolute inset-0 h-full w-full bg-gradient-to-bl from-main-400/50 to-transparent"></div>  */}
                          {/* <img class="z-[2] opacity-25 mix-blend-overlay absolute -bottom-1/3 w-full left-1/2 -translate-x-1/2" src="/assets/TermgameSI.jpeg" alt="promotion fire!" />  */}
                          <div class="z-[1] font-medium absolute top-0 right-0 bg-main-400 rounded-tl-md rounded-br-md">
                            <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                              ส่วนลด : {gameItem.percentShow} %
                            </p>
                          </div>
                          <div class="z-[1] font-medium absolute bottom-0 left-0 bg-main-400 rounded-tl-md rounded-br-md">
                            <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                              {gameItem.company_name}
                            </p>
                          </div>
                          <img
                            src={`/assets/game/product/${gameItem.company_id || "none1-1"}.png`}
                            class="w-full object-cover h-full"
                            alt={gameItem.company_id}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div class="mb-3 flex items-center justify-between mt-28">
              <div>
                <h2 class="mb-3 text-xl font-bold text-gray-50">บัตรเงินสด</h2>
                <p class="mb-3 text-sm font-bold text-gray-200">Cash Card</p>
              </div>
              <button
                type="button"
                onClick={() => navigate("/shop/cashcard-topup")}
                class="text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 focus:ring-4 focus:outline-none focus:ring-pink-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                ดูทั้งหมด
              </button>
            </div>

            <Slider {...settings}>
              {card && Array.isArray(card) ? (
                card.map((cardItem) => (
                  <div
                    title={cardItem.company_id}
                    key={cardItem.company_id}
                    onClick={() => navigate(`/shop/cashcard/${cardItem.company_id}`)}
                  >
                    <div style={{ width: "130px", marginRight: "5px" }}>
                      <div class="flex items-center justify-between mt-5">
                        <div class="relative overflow-hidden rounded-md aspect-prepaid-card">
                          {/* <div class="z-[1] font-medium absolute top-0 right-0 bg-main-400 rounded-tl-md rounded-br-md">
                            <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                              ส่วนลด : 0 %
                            </p>
                          </div> */}
                          <div class="z-[1] font-medium absolute bottom-0 left-0 bg-main-400 rounded-tl-md rounded-br-md">
                            <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                              {cardItem.company_name}
                            </p>
                          </div>
                          <img
                            src={`/assets/cashcard/product/${cardItem.company_id}.png`}
                            class="w-full object-cover h-full"
                            alt={cardItem.company_name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div class="mx-auto max-w-screen-sm text-center mt-12">
                  {card ? (
                    <Spinner color="purple" size="md" />
                  ) : (
                    <h1 className="text-lg font-semibold p-2 text-gray-500">
                      ไม่มีข้อมูล
                    </h1>
                  )}
                </div>
              )}
            </Slider>
          </div>
        </>
    </>
  );
};

export default Home;
