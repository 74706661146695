import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";

function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [user, setUser] = useState({});

  const fetchData = async () => {
    localStorage.setItem("loading", "true");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem("loading", "false");
        setUser(data.user);
      } else {
        localStorage.setItem("loading", "false");
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      Cookies.remove("HNAWNYToken");
      toast.success("Successfully logged out");
      navigate(`/auth/login`);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Profile</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box
          mt={12}
          p={4}
          boxShadow={5}
          bgcolor="background.paper"
          borderRadius={4}
        >
          <Typography variant="h4" align="center" gutterBottom>
            โปรไฟล์
          </Typography>
          <Box my={4}>
            <Typography variant="h5" align="center">
              ยินดีตอนรับ {user.acc_username} จ้าาาาาา
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              เป็นสมาชิกเมื่อ: {user.acc_date}
            </Typography>
          </Box>

          <Divider />

          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              ข้อมูล
            </Typography>
            <Box>
              <Typography variant="body1">
                ยอดเงินคงเหลือ: {user.acc_credit} บาท
              </Typography>
              <Typography variant="body1">
                เคดิตเงินคืนคงเหลือ: {user.acc_point} HCG
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box mt={4}>
            <Typography variant="h5" align="center" gutterBottom>
              เพิ่มเติม
            </Typography>
            <Box mt={2}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate("/app/shop/api")}
                sx={{ mb: 2 }}
              >
                <i className="bi bi-shop"></i>&nbsp;Client ID ของคุณ
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate("/app/setting")}
                sx={{ mb: 2 }}
              >
                <i className="bi bi-gear"></i>&nbsp;ตั้งค่า
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={logout}
                disabled={loading}
                sx={{
                  bgcolor: "#f2383b",
                  color: "white",
                  "&:hover": {
                    bgcolor: "#d42b2e",
                  },
                }}
              >
                <> <i className="bi bi-door-closed"></i>&nbsp; ออกจากระบบ</>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Profile;
