import { useNavigate } from "react-router-dom";
const logoStyle = {
  width: "130px",
  height: "auto",
  cursor: "pointer",
};
const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
        <footer class="bg-gray-900 rounded-lg mt-24">
            <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <div onClick={() => navigate("/app/")} class="flex items-center sm:text-center  mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                    <img
                        src={"/assets/icon.png"}
                        style={logoStyle}
                        alt="เติมเกมสิ.ico"
                        onClick={() => navigate("/app/")}
                      />
                    </div>
                    <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-50 sm:mb-0 ">
                        <li>
                            <div onClick={() => navigate('/app/')} class="hover:underline me-4 md:me-6"><i class="bi bi-house"></i> หน้าแรก</div>
                        </li>
                        <li>
                          <div onClick={() => navigate('/app/payment')} class="hover:underline me-4 md:me-6"><i class="bi bi-wallet-fill"></i> เติมเงิน</div>
                        </li>
                        <li>
                          <div onClick={() => navigate('/app/tracking')} class="hover:underline me-4 md:me-6"><i class="bi bi-ui-checks"></i> เช็คสถานะรายการ</div>
                        </li>
                    </ul>
                </div>
                <hr class="my-6 border-purple-200 sm:mx-auto lg:my-8" />
                <span class="block text-sm text-gray-50 sm:text-center ">Powered by <a href='https://hnawny.in.th/' className="font-bold">Hnawny Cloud House</a> & <a href='https://hnawny.in.th/' className="font-bold">MUCITY</a> © 2022 - {new Date().getFullYear()}{' '} All rights reserved</span>
                <div class="flex items-center space-x-4 mt-3">
                    <img src="/Logo-html.svg" width={50} alt="Hnawny Cloud House" class="w-12 h-12"/>
                    <img src="/muonlinelogotrans.png" width={50} alt="Mucity.shop" class="w-12 h-12"/>
                </div>

            </div>
        </footer>
    </>
  );
};

export default Footer;
