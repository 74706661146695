import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Container,
  Box,
  Button
} from '@mui/material';

function Tracking() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [user, setUser] = useState({});
  const [rows, setTransactions] = useState(null); // Initialize with null
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
       localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTracking = async (type) => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}gettracking?type_topup=${type || "gtopup"}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setTransactions(data.data);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    fetchTracking();
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Tracking</title>
      </Helmet>
      <Container maxWidth="lg" className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
        <Box mt={12} p={3} boxShadow={5} bgcolor="background.paper" borderRadius={4}>
          <h2 className="text-lg font-semibold p-2">เช็คสถานะรายการ</h2>
          <div className="flex items-center space-x-4">
            <div>
              <h1 className="text-2xl font-bold">{user.acc_mobile}</h1>
              <p className="text-purple-400">เป็นสมาชิกเมื่อ : {user.acc_date}</p>
            </div>
          </div>

          <div className="flex justify-center space-x-4 mt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => fetchTracking('gtopup')}
            >
              เติมเกม
            </button>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => fetchTracking('cashcard')}
            >
              บัตรเงินสด
            </button>
            {/* <button
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => fetchTracking('billpay')}
            >
              จ่าบิล
            </button> */}
          </div>


          {rows !== null && rows.length > 0 ? (
            <>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>สินค้า</TableCell>
                    <TableCell>รายละเอียด</TableCell>
                    <TableCell>สถานะ</TableCell>
                    <TableCell>วันทำรายการ</TableCell>
                    <TableCell>รายละเอียด</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={row.order_id}>
                      <TableCell component="th" scope="row">
                        {row.product}
                      </TableCell>
                      <TableCell><p className="text-gradient-to-br text-1xl" dangerouslySetInnerHTML={{ __html: `${row.description}` }}></p></TableCell>
                      <TableCell>
                      <span className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded text-gray-700 ${
                        row.status === '2' ? 'bg-green-300 text-green-700 border border-green-300' :
                        row.status === '1' ? 'bg-yellow-300 text-yellow-700 border border-yellow-300' :
                        row.status === '4' ? 'bg-red-300 text-red-700 border border-red-300' : ''}`}>
                        {row.status === '2' ? 'รายการสำเร็จ' : row.status === '1' ? 'กำลังทำรายการ' : row.status === '4' ? 'รายการล้มเหลว กรุณาติดต่อแอดมิน' : row.status}
                      </span>
                    </TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => {
                          navigate(`/app/profile/purchase/callback/details/${row.dest_ref}`);
                        }}
                        className="mt-3 border font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-purple-900 bg-white border border-purple-300 focus:outline-none hover:bg-purple-100 focus:ring-4 focus:ring-purple-100"
                      >
                        <i className="bi bi-search"></i>&nbsp;รายละเอียด
                      </Button>
                    </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
            </>
            ) : (
                <div className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center mt-12">
                        <h1 className="text-purple-600 dark:text-purple-200">ไม่พบรายการ</h1>      
                  </div>   
                </div>
            )}

          </Box>
      </Container>
    </>
  );
}

export default Tracking;
