import React from "react";
import { useNavigate } from 'react-router-dom';

const ERROR = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="min-h-screen flex items-center justify-center relative mb-16"
        style={{ top: 0}}
        data-aos="zoom-out-up"
      >
         <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class="mx-auto max-w-screen-sm text-center">
                <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-purple-600">404</h1>
                <p class="mb-4 text-3xl tracking-tight font-bold text-purple-900 md:text-4xl">Not Found</p>
                <p class="mb-4 text-lg font-light text-purple-500">หน้าที่คุณกำลังค้นหาไม่มีอยู่</p>
                <div onClick={() => navigate(-1)} class="inline-flex text-white bg-purple-600 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">ย้อนกลับ</div>
            </div>   
        </div>
    </section>
      </div>
    </>
  );
};

export default ERROR;
