import React, { useState, useEffect } from 'react';

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Turnstile } from '@marsidev/react-turnstile'
import Cookies from 'js-cookie';
import { toast} from 'react-hot-toast';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";



function Login() {
  const navigate = useNavigate();
  const [Cresponse, setTurnstileToken] = useState();
  const [loading, setLoading] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const hzcToken = Cookies.get("HNAWNYToken");



  const handleSubmit = async (event) => {
    event.preventDefault();
    const acc_username = event.target.username ? event.target.username.value : '';
    const acc_password = event.target.password ? event.target.password.value : '';
    const requestData = { acc_password, acc_username, Cresponse};
    setLoading(true);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData),
        });
  
        const data = await response.json();
        if (data.status === 200) {
          toast.success(data.message);
          Cookies.set('HNAWNYToken', data.accessToken); // expires คือ 7 วัน
          setTimeout(() => {
            navigate('/app/profile');
          }, 2000);
        } else if (data.status === 404) {
          navigate(`/auth/register?phone=${acc_username}`);
          Cookies.remove('HNAWNYToken');
        } else if (data.status === 403) {
          Cookies.remove('HNAWNYToken');
          setShowPasswordInput(true);
          setLoading(false);
          toast.success(data.message);
        } else {
          Cookies.remove('HNAWNYToken');
          setLoading(false);
          toast.error(data.message);
        }        
      } catch (error) {
        setLoading(false);
        toast.error('เกิดข้อผิดพลาดระหว่างการเข้าสู่ระบบ: ' + error, {autoClose: 5000,});
      }

    
  };

  useEffect(() => {
    if (hzcToken) {
      navigate('/app/profile');
    }
  }, [hzcToken]);
  


  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Auth Login</title>
      </Helmet>
      <div
        className="min-h-screen flex items-center justify-center relative mb-16"
        style={{ top: 0, marginTop: "20px" }}
      >
        <form class="mx-auto" onSubmit={handleSubmit}  data-aos="zoom-out-up">
          <div className="w-full p-10">
          <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "clamp(2rem, 5vw, 1rem)",
                color: "primary.main",
              }}
            >
              ยินดีตอนรับ
            </Typography>
            <Typography class="self-center font-semibold ">
             Termgame SI <sub> By Hnawny Cloud X MUCITY</sub>
            </Typography>

            <div className="form-control flex flex-col w-full mt-6">
              <Typography variant="caption">เบอร์โทรศัพท์ที่สามารถติดต่อได้</Typography>
              <TextField
                readOnly={loading}
                type="number"
                name='username'
                placeholder="06XXXXXXXX"
                variant="outlined"
                required
              />
            </div>
            {showPasswordInput && (
              <div className="form-control flex flex-col w-full mt-3 login-reg">
                <Typography variant="caption">รหัสผ่าน</Typography>
                <TextField
                  type="password"
                  name='password'
                  readOnly={loading}
                  placeholder="*****"
                  variant="outlined"
                  />
                <span class="mt-5 text-xs font-medium label-text-alt me-2 px-2.5 py-0.5 rounded" onClick={() => navigate("/auth/resetpassword")}>ลืมรหัสผ่าน</span>
              </div>
            )}
             <Turnstile 
                siteKey={process.env.REACT_APP_SITEKEY}
                options={{
                    action: 'submit-form',
                    theme: 'auto',
                    size: 'invisible',
                }} 
                onSuccess={setTurnstileToken}
            />
            <div className="col mt-6 space-y-4">
              <Button
                disabled={loading}
                loading={loading}
                variant="contained"
                className="w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                type="submit"
              >
                <><i class="bi bi-arrow-up-right-square-fill"></i>&nbsp;ดำเนินการต่อ</>
              </Button>
              <span
                className="label-text-alt text-md w-full block"
                style={{ color: "#ad001d" }}
              >
                ** หากท่านต้องการสมัครสมาชิกให้ทำการกรอกเบอร์มือถือแล้วก็ดำเนินการต่อ (เบอร์ไทยเท่านั้น) **
              </span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
