import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Container,
  Box,
  Button
} from '@mui/material';

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {  Typography, Paper } from "@mui/material";

function ApplicationsDevIndex() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [user, setUser] = useState({});
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
           localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTracking = async () => {
    localStorage.setItem('loading', 'true');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}myApplications`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
           localStorage.setItem('loading', 'false');
        setRows(data.data);
      } else if (data.status === 403 || data.status === 401) {
           localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      } else {
           localStorage.setItem('loading', 'false');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTracking();
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | GAME's API</title>
      </Helmet>
        <div className="min-h-screen justify-center items-center p-4 overflow-auto" data-aos="zoom-out-up">
          <div className="p-2 space-y-4 rounded-md p-6 w-full rounded-xl lg:w-9/12 " style={{ margin: 'auto', marginTop: "13vh" }}>
            <div className="flex items-center space-x-4">
              <div>
                <Typography variant="h4" gutterBottom>GAME's API</Typography>
                <Typography variant="subtitle1" gutterBottom>เกม API ของคุณ</Typography>
                <Button variant="contained" onClick={() => navigate('/app/applications/dev/create')}>สร้าง GAME's API</Button>
              </div>
            </div>

            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ชื่อ</TableCell>
                    <TableCell>สถานะ</TableCell>
                    <TableCell>รายละเอียด</TableCell>
                    <TableCell>สร้างเมื่อ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
                  ).map((item) => (
                    <TableRow key={item.applications_id} onClick={() => navigate(`/app/applications/dev/my/${item.applications_id}`)} style={{ cursor: 'pointer' }}>
                      <TableCell>{item.applications_name}</TableCell>
                      <TableCell>
                      <Box
                          component="span"
                          className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded text-gray-700 ${
                            item.applications_active === 'true' ? 'bg-green-300 text-green-700 border border-green-300' : 'bg-red-300 text-red-700 border border-red-300'}`
                          }
                        >
                          {item.applications_active === "true" ? 'เปิดใช้งาน' : 'ปิดการใช้งานชั่วคราว'}
                        </Box>
                      </TableCell>
                      <TableCell>{item.applications_details}</TableCell>
                      <TableCell>{item.applications_createdAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
    </>
  );
}

export default ApplicationsDevIndex;
