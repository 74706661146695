import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

function Payment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [details, setdetails] = useState({});

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Getdetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}details_Payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
          body: JSON.stringify({ code: id }),
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setdetails(data.data);
      } else {
        navigate("/app/transactions");
        toast.error(data.message);
        localStorage.setItem('loading', 'false');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    Getdetails();
    fetchData();
  }, []);

  const type = details.type === 'angpao' ? 'ซองของขวัญ' : 
    details.type === 'wallet_webhook' ? 'ทรูวอลเล็ต QR' :
    details.type === 'credit_debit_card' ? 'บัตรเคดิต / เดบิต' :
    details.type === 'slip' ? 'Internet banking' :
    details.type === 'coupon' ? 'คูปอง' : 'ไม่พบช่องทางการชำระเงิน';

  let statusColorClass = '';
  let statusText = '';

  // เช็คสถานะและกำหนดสีและข้อความสถานะตามที่ต้องการ
  if (details.status === 'approve') {
    statusColorClass = 'bg-gray-700 text-green-300 border border-green-300';
    statusText = 'approve';
  } else if (details.status === 'wait') {
    statusColorClass = 'bg-gray-700 text-yellow-300 border border-yellow-300';
    statusText = 'wait';
  } else if (details.status === 'expire' || details.status === 'cancel') {
    statusColorClass = 'bg-gray-700 text-red-300 border border-red-300';
    statusText = details.status;
  }

  const Cancel = async () => {
    const requestData = { payment_id: id, t: "wallet_webhook" };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}cancelPayment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${hzcToken}`,
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    if (data.status === 200) {
      toast.success(data.message);
      navigate(`/app/transactions`);
    } else {
      toast.error(data.message);
    }
  }

  var formattedNumber = details.amount;

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Payment</title>
      </Helmet>

      <Container maxWidth="lg" className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
        <Box mt={12} p={6} boxShadow={5} bgcolor="background.paper" borderRadius={4}>
          <Grid container justifyContent="center"  borderRadius={8}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h5" >
                  รายละเอียดรายการ
                </Typography>
                <Typography variant="caption" mb={4}>
                  #{id}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={2}>
                      ช่องทางการชำระเงิน
                    </Typography>
                    <Typography variant="body1" mb={2}>
                      {type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={2}>
                      สถานะรายการ
                    </Typography>
                    <span className={statusColorClass}>
                      {statusText}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={2}>
                      จำนวนเงิน
                    </Typography>
                    <Typography variant="body1" mb={2}>
                      {formattedNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={2}>
                      วันเวลาทำรายการ
                    </Typography>
                    <Typography variant="body1" mb={2}>
                      {details.created_at}
                    </Typography>
                  </Grid>
                </Grid>
                {details.status === 'wait'  ? (
                  <Button type="button" onClick={Cancel}  variant="outlined" sx={{ mt: 3 }}>
                    <><svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>ยกเลิกรายการนี้</>
                  </Button>
                ) : (
                  <h1 className="text-lg font-semibold">ขอบคุณสำหรับการชำระเงิน</h1>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Payment;
