import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie"; 
import { CircularProgress, Typography, Button, Grid, Paper, Box, Divider } from "@mui/material";

function ApplicationsDevInfo() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [rows, setRows] = useState([]);
  const [showSecret, setShowSecret] = useState(false); // State สำหรับติดตามว่าต้องการแสดงข้อมูล Secret หรือไม่

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 403 || data.status === 401) {
       localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTracking = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}infoApplications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify({ code: id }),
      });
      const data = await response.json();
      if (data.status === 200) {
       localStorage.setItem('loading', 'false');
        setRows(data.data);
      } else {
        navigate('/app/applications/dev/')
       localStorage.setItem('loading', 'false');
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const RevokeSecret = async (event) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}RevokeSecretApplication`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
       body: JSON.stringify({ code: id }),
      });
      const data = await response.json();
      if (response.ok) {
        fetchTracking();
        setShowSecret(false)
        toast.success("Revoke Application Secret successfully");
      } else {
        toast.error(data.message || "Failed to create application");
      }
    } catch (error) {
      console.error("Error creating application:", error);
      toast.error("Failed to create application");
    }
  };

  useEffect(() => {
    fetchTracking();
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | GAME's API INFo {rows.applications_name || ""}</title>
      </Helmet>
        <Grid container justifyContent="center" alignItems="center" minHeight="100vh">
          <Grid item xs={12} md={8} lg={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>GAME's API Info</Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 3 }}>รายละเอียด แอปพลิเคชั่น ของคุณ</Typography>
              <Button variant="contained" onClick={() => navigate('/app/applications/dev')} sx={{ mb: 2 }}>ย้อนกลับ</Button>
              <Divider sx={{ mb: 3 }} />
              <Typography variant="h5" sx={{ mb: 1 }}>GAME's API Name : {rows.applications_name}</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>GAME's API Details : {rows.applications_details}</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <span className={`font-bold bg-${rows.applications_active === "true" ? 'green' : 'red'}-100 text-${rows.applications_active === "true" ? 'green' : 'red'}-800 px-2.5 py-0.5 rounded border border-${rows.applications_active === "true" ? 'green' : 'red'}-400`}>
                  สถานะ {rows.applications_active === "true"  ? 'เปิดใช้งาน' : 'ปิดการใช้งานชั่วคราว'}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>GAME's API Domain : {rows.applications_hostname}</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>GAME's API whitelist IP : {rows.applications_whitelistIP}</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>GAME's API CreatedAt : {rows.applications_createdAt}</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>GAME's Secret ID : {rows.applications_secret}</Typography>
              <Button variant="contained" mt={5} fullWidth onClick={() => navigate(`/app/applications/dev/edit/${id}`)} sx={{ mr: 2 }}>แก้ไข GAME's API</Button>
              <Box mt={2}>
              <Button variant="contained" onClick={RevokeSecret} sx={{ mr: 2 }}>เปลี่ยน GAME's API Secret ID</Button>
              <Button variant="contained" sx={{ bgcolor: 'red.800', color: 'white' }}>ลบ GAME's API</Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
    </>
  );
}

export default ApplicationsDevInfo;
