import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

function Purchase_details() {
  const navigate = useNavigate();
  const { id } = useParams();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [details, setdetails] = useState({});

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Getdetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}Purchase_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
          body: JSON.stringify({ code: id }),
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setdetails(data.data);
      } else {
        navigate("/app/tracking");
        toast.error(data.message);
        localStorage.setItem('loading', 'false');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    Getdetails();
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | รายละเอียดรายการสั่งซื้อ #{id}</title>
      </Helmet>

      <Container maxWidth="" className="py-8 px-4 mx-auto flex items-center justify-center relative mb-16 lg:py-16 lg:px-6">
        <Box mt={12} p={2} boxShadow={5} bgcolor="background.paper" borderRadius={4}>
          <Grid container justifyContent="center"  borderRadius={8}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h5" >
                  รายละเอียดรายการสั่งซื้อ
                </Typography>
                <Typography variant="caption" mb={4}>
                  #{id}
                </Typography>
                <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1}>
                      สินค้า
                    </Typography>
                    <Typography variant="body1" mb={1}>{details.product}</Typography>
                    <Typography variant="body1" mb={1} dangerouslySetInnerHTML={{ __html: details.description}}></Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1}>
                      ข้อมูล
                    </Typography>
                    <Typography variant="body1" mb={1} >ID / ข้อมูลสินค้า : {details.ref}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1}>
                      สถานะรายการ
                    </Typography>
                    <span className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded text-gray-700 ${
                        details.status === '2' ? 'bg-green-300 text-green-700 border border-green-300' :
                        details.status === '1' ? 'bg-yellow-300 text-yellow-700 border border-yellow-300' :
                        details.status === '4' ? 'bg-red-300 text-red-700 border border-red-300' : ''}`}>
                        {details.status === '2' ? 'รายการสำเร็จ' : details.status === '1' ? 'กำลังทำรายการ' : details.status === '4' ? 'รายการล้มเหลว กรุณาติดต่อแอดมิน' : details.status}
                    </span><br />
                    <Typography variant="body1" mt={2} mb={2}>
                          {details.sms}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" mb={1}>
                        จำนวนเงิน
                      </Typography>
                      <Typography variant="body1" mb={1}>
                        ราคาเต็ม <b>{details.price}</b>
                      </Typography>
                      <Typography variant="body1" mb={1}>
                        ราคาที่จ่าย <b>{details.sale_price}</b>
                      </Typography>
                      <Typography variant="body1" mb={1}>
                        ส่วนลดที่ได้ <b>{details.discount_percentage} %</b>
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={2}>
                      การชำระเงิน
                    </Typography>
                    <Typography variant="body1" mb={2}>
                      {details.created_at}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={2}>
                      วิธีชำระเงิน
                    </Typography>
                    <Typography variant="body1" mb={2}>
                      ชำระเงินด้วย {details.pay_methods === 'Credit' ? 'เงินสด' : details.pay_methods === 'Point' ? 'พ้อย' : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <h1 className="text-lg font-semibold">ขอบคุณสำหรับการสั่งซื้อ</h1>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Purchase_details;
