import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Menu from "@mui/material/Menu";
import { LinearProgress } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



const logoStyle = {
  width: "70px",
  height: "auto",
  cursor: "pointer",
  marginTop: 2,
  marginLeft: 10
};

function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  

  const [anchorElM, setAnchorElM] = React.useState(null);
  const [anchorElPC, setAnchorElPC] = React.useState(null);

  const openM = Boolean(anchorElM);
  const openPC = Boolean(anchorElPC);

  const handleClickM = (event) => {
    setAnchorElM(event.currentTarget);
  };
  const handleCloseM = () => {
    setAnchorElM(null);
  };

  const handleClickPC = (event) => {
    setAnchorElPC(event.currentTarget);
  };
  const handleClosePC = () => {
    setAnchorElPC(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const to = (sectionId) => {
    setOpen(false);
    navigate(`${sectionId}`);
  };

  const fetchSomeData = async (Token) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setData(data.user);
        setTimeout(() => {
          setLoading(false);
        }, 2000); // 2000 มีนาคมหรือ 2 วินาที
      } else if (data.status === 403 || data.status === 401) {
        setLoading(false);
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      // handle fetch error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSomeData(hzcToken);
  }, [hzcToken]);

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      Cookies.remove("HNAWNYToken");
      toast.success("ออกจากระบบสำเร็จ");
      navigate(`/auth/login`);
    }, 2000); // 2000 มีนาคมหรือ 2 วินาที
  };


  // const [loading, setLoading] = useState(localStorage.getItem('loading') === 'true');

  useEffect(() => {
    const startLoading = () => {
      setLoading(true);
      localStorage.setItem('loading', 'true');
    };

    const endLoading = () => {
      setLoading(false);
      localStorage.setItem('loading', 'false');
    };

    window.addEventListener('load', endLoading);
    window.addEventListener('beforeunload', startLoading);

    return () => {
      window.removeEventListener('load', endLoading);
      window.removeEventListener('beforeunload', startLoading);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading(localStorage.getItem('loading') === 'true');
    }, 100); // ตรวจสอบทุก 100 milliseconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ backgroundColor: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <span className="leaderboard__ac">
            <CircularProgress color="inherit" disableShrink />
            <h1>กำลังเชื่อมต่อ Gateway</h1>
        </span>
        
      </Backdrop>
       {loading && <LinearProgress />}
      <AppBar
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 1,
        }}>
          
        <Container  maxWidth="full">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              // display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "9px",
              bgcolor: "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(27px)",
              // maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                "0 0 1px #512DA8, 1px 1.5px 2px -1px #BD2A93, 4px 4px 12px -2.5px #4527A0",
            })}
          >
            
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <img
                src={"/assets/icon.png"}
                style={logoStyle}
                alt="เติมเกมสิ.ico"
                onClick={() => navigate("/app/")}
              />

              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  onClick={() => { navigate(`/app/`);  setOpen(false);}}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    <i class="bi bi-house"></i> หน้าแรก
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {navigate(`/shop/termgame`); setOpen(false);}}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    <i class="bi bi-controller"></i> เติมเกมออนไลน์
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {navigate(`/shop/cashcard-topup`); setOpen(false);}}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    <i class="bi bi-credit-card"></i> บัตรเงินสด
                  </Typography>
                </MenuItem>
                {data.acc_id && (
                  <>
                    <MenuItem
                      onClick={() => {navigate("/app/payment"); setOpen(false);}}
                      sx={{ py: "6px", px: "12px" }}
                    >
                      <Typography variant="body2" color="text.primary">
                        <i class="bi bi-wallet-fill"></i> เติมเงิน
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {navigate("/app/tracking"); setOpen(false);}}
                      sx={{ py: "6px", px: "12px" }}
                    >
                      <Typography variant="body2" color="text.primary">
                        <i class="bi bi-ui-checks"></i> เช็คสถานะรายการ
                      </Typography>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  onClick={() => {navigate("faq"); setOpen(false);}}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {!data.acc_id && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  onClick={() => {navigate("/auth/login"); setOpen(false);}}
                >
                  เข้าสู่ระบบ / สมัคร
                </Button>
              )}
              {data.acc_id && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    id="nav-pc"
                    aria-controls={openPC ? "nav-pc" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openPC ? "true" : undefined}
                    onClick={handleClickPC}
                  >
                    บัญชีของฉัน
                  </Button>
                  <Menu
                    id="nav-pc"
                    aria-labelledby="nav-m"
                    anchorEl={anchorElPC}
                    open={openPC}
                    onClose={handleClosePC}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={() => {navigate("/app/profile"); setOpen(false);}}>
                      <i className="bi bi-person-bounding-box"></i>&nbsp;โปรไฟล์
                    </MenuItem>
                    <MenuItem onClick={() => {navigate("/app/setting"); setOpen(false);}}>
                      <i className="bi bi-gear"></i>&nbsp;ตั้งค่า
                    </MenuItem>
                    <MenuItem onClick={() => {navigate("/app/transactions"); setOpen(false);}}>
                      <i class="bi bi-clock-history"></i>&nbsp;ประวัติ
                    </MenuItem>
                    <MenuItem
                      onClick={() => { navigate("/app/applications/dev/"); setOpen(false);}}
                    >
                      {" "}
                      <i class="fa-solid fa-brackets-curly"></i>
                      &nbsp;สำหรับผู้พัฒนา
                    </MenuItem>
                    <MenuItem onClick={() => {logout(); setOpen(false);}}>ออกจากระบบ</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer
                anchor="right"
                open={open}
                className="backdrop"
                onClose={toggleDrawer(false)}
              >
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <img
                    src={"/assets/icon.png"}
                    style={logoStyle}
                    alt="เติมเกมสิ.ico"
                    onClick={() => {navigate("/app/"); setOpen(false);}}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  ></Box>
                  <MenuItem
                    onClick={() => {navigate(`/app/`); setOpen(false);}}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      <i class="bi bi-house"></i> หน้าแรก
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => { navigate(`/shop/termgame`); setOpen(false);}}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      <i class="bi bi-controller"></i> เติมเกมออนไลน์
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => { navigate(`/shop/cashcard-topup`); setOpen(false);}}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      <i class="bi bi-credit-card"></i> บัตรเงินสด
                    </Typography>
                  </MenuItem>
                  {data.acc_id && (
                    <>
                      <MenuItem
                        onClick={() => { navigate("/app/payment"); setOpen(false);}}
                        sx={{ py: "6px", px: "12px" }}
                      >
                        <Typography variant="body2" color="text.primary">
                          <i class="bi bi-wallet-fill"></i> เติมเงิน
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => { navigate("/app/tracking"); setOpen(false);}}
                        sx={{ py: "6px", px: "12px" }}
                      >
                        <Typography variant="body2" color="text.primary">
                          <i class="bi bi-ui-checks"></i> เช็คสถานะรายการ
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={() => to("faq")}>FAQ</MenuItem>
                  <Divider />
                  {!data.acc_id && (
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        component="a"
                        onClick={() => {navigate("/auth/login"); setOpen(false);}}
                        target="_blank"
                        sx={{ width: "100%" }}
                      >
                        เข้าสู่ระบบ / สมัคร
                      </Button>
                    </MenuItem>
                  )}
                  {data.acc_id && (
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          id="nav-m"
                          aria-controls={openM ? "nav-m" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openM ? "true" : undefined}
                          onClick={handleClickM}
                          sx={{ width: "100%" }}
                        >
                          Account Setting
                        </Button>
                        <Menu
                          id="nav-m"
                          aria-labelledby="nav-m"
                          anchorEl={anchorElM}
                          open={openM}
                          onClose={handleCloseM}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={() => {navigate("/app/profile"); setOpen(false);}}>
                            <i className="bi bi-person-bounding-box"></i>
                            &nbsp;โปรไฟล์
                          </MenuItem>
                          <MenuItem onClick={() => {navigate("/app/setting"); setOpen(false);}}>
                            <i className="bi bi-gear"></i>&nbsp;ตั้งค่า
                          </MenuItem>
                          <MenuItem
                            onClick={() => {navigate("/app/transactions"); setOpen(false);}}
                          >
                            <i class="bi bi-clock-history"></i>&nbsp;ประวัติ
                          </MenuItem>
                          <MenuItem
                            onClick={() => {navigate("/app/applications/dev/"); setOpen(false);}}
                          >
                            {" "}
                            <i class="fa-solid fa-brackets-curly"></i>
                            &nbsp;สำหรับผู้พัฒนา
                          </MenuItem>
                          <MenuItem onClick={() => {logout(); setOpen(false);}}>ออกจากระบบ</MenuItem>
                        </Menu>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
