import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from '@mui/material/Alert';



const Termgame = () => {
  const { id } = useParams();
  const [loadingPay, setLoadingPay] = useState(false);
  const [gameItem, setGame] = useState({});
  const [package_game, setPackage] = useState([]);
  const [server_game, setServer] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedServer, setSelectedServer] = useState("");
  const [username, setUsername] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("0.00"); // เพิ่ม state สำหรับราคาของแพ็คเกจ
  const [percent, setPercent] = useState("0.00"); // เพิ่ม state สำหรับราคาของแพ็คเกจ
  const [description, setdescription] = useState(""); // เพิ่ม state สำหรับราคาของแพ็คเกจ
  const navigate = useNavigate();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [user, setUser] = useState({});
  const [tos, setTOS] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    if (!isChecked) {
      setIsChecked(true);
      setTOS(true);
    } else {
      setIsChecked(false);
    }
  };
  
  

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
       
      } else if (data.status === 403 || data.status === 401) {
        toast.error(data.message);
        Cookies.remove('HZCSToken');
      }
    } catch (error) {
      // handle fetch error
      console.error("Error fetching data:", error);
    }
  };

  
const GetGameTT = async () => {
  localStorage.setItem('loading', 'true');
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}GameId`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify({ game_name: id }),
      }
    );
    const data = await response.json();
    if (data.status === 200) {
     localStorage.setItem('loading', 'false');
      setGame(data.data);
      setPackage(data.package);
      setServer(data.gameservers);
      setPercent(data.percentShow);
    } else if (data.status === 503) {
        setLoadingPay(false)
        toast.error(data.message);
        navigate('/shop/termgame/');
     } else {
      toast.error(data.message);
     localStorage.setItem('loading', 'false');
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  useEffect(() => {
    fetchData();
    GetGameTT();
  }, []);
  

  const handlePurchase = async () => {
    setLoadingPay(true)
    if(!username){
      setLoadingPay(false)
      return toast.error("กรุณากรอกไอดีเกม");
    }
    if(!selectedPackage){
      setLoadingPay(false)
      return toast.error("กรุณาเลือกแพคเกจ");
    }
    if(!isChecked){
      setLoadingPay(false)
      return toast.error("กรุณายอมรับข้อตกลง");
    }
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}purchaseGtopup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
          body: JSON.stringify({
            ref: username,
            server: selectedServer,
            id: selectedPackage,
            product: id,
            description:description
          }),
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setLoadingPay(false)
        toast.success("ดำเนินรายการสำเร็จ!");
        navigate('/app/tracking');
      } else {
        setLoadingPay(false)
        toast.error(data.message);
      }
    } catch (error) {
      setLoadingPay(false)
      console.error("Error making purchase:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | {gameItem.company_name || ""}</title>
      </Helmet>
        <>
          <div
            className="min-h-screen container mx-auto mt-4 mb-20"
            data-aos="zoom-out-up"
          >
            <div
              className="rounded-lg"
              style={{
                maxWidth: "1920px",
                padding: "6rem 0 0 0",
                position: "relative",
              }}
            ></div>

            <div className="grid grid-cols-12 gap-6 px-4">
              <div className="col-span-12">
                <div
                  className="rounded-2xl text-white relative selectedPackage"
                  style={{ backgroundSize: "cover" }}
                >
                  <img
                    className="absolute rounded-2xl w-full h-full object-cover"
                    src={`/assets/game/banner/${gameItem.company_id}.png`}
                    alt={`${gameItem.company_id}`}
                  />
                  <div className="rounded-2xl px-6 py-10 backdrop-blur-sm backdrop-brightness-[60%]">
                    <p className="mt-3 mb-2 text-xl font-bold">
                      {gameItem.company_name}
                    </p>
                    <p className="text-sm">{gameItem.game_info}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid md:grid-cols-2 sm:gap-2 gap-y-5 p-3">
              <div className="mb-4 mt-4 mr-2">
              <Chip label="Step 1 | วิธีเอา : ID ผู้เล่น" color="primary" />
                <img
                  src={`/assets/game/tutorial/${gameItem.company_id}.png`}
                  className="w-full rounded-md p-6 shadow-md w-full border rounded-xl border-gray-900 mt-3 mb-2"
                  alt={gameItem.company_id}
                />
                <div
                  class="flex p-4 mb-4 text-sm rounded-lg border border-red-300 bg-gray-800 text-red-400 mt-4"
                  role="alert"
                >
                  <svg
                    class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span class="sr-only">Danger</span>
                  <div>
                    <span class="font-medium">เงื่อนไขการให้บริการ</span>
                    <ul class="mt-1.5 list-disc list-inside">
                      <li>
                        ถ้าหากลูกค้ากรอกเลข ID มาผิด
                        ทางเราจะไม่รับผิดชอบและไม่มีการคืนเงิน ทุกรณี
                      </li>
                      <li>
                        หลังจากดำเนินการสั่งซื้อสำเร็จ กรุณารอ 2 - 3 นาที
                        ยอดเงินจะเข้าเกม
                      </li>
                      <li>
                        จะไม่มีการยกเลิกรายการ และขอคืนเงินหรือเครดิต
                        หลังการทำรายการแล้ว ทุกรณี
                      </li>
                      <li>
                        หากมีข้อสงสัยสามารถ กดที่แชทด้านขวามือ หรือ ติดต่อ
                        เพจได้เลยครับ
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mb-4 mt-4">
              <Chip label="Step 2 | กรอก ID ผู้เล่น" color="primary" />
                <div className="w-full rounded-md p-6 shadow-md w-full border rounded-xl border-gray-900 mt-3 mb-2">
                  <div className="mb-2">
                    <p className="mb-2 font-bold">ID ผู้เล่น (ดูได้จากภาพตัวอย่าง)</p>
                    <TextField
                      type="text"
                      name="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      variant="outlined"
                      className="w-full"
                      required=""
                      placeholder={""}
                    />
                  </div>
                  {server_game.length > 0 && (
                    <div class="mb-2 mt-3">
                      <label
                        for="countries"
                        class="font-bold"
                      >
                        โปรดเลือกเซิฟเวอร์
                      </label>
                      <select
                        id="countries"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5"
                        onChange={(e) => setSelectedServer(e.target.value)}
                      >
                        <option selected>กรุณาเลือก</option>
                        {Array.isArray(server_game) &&
                          server_game.map((serverItem) => (
                            <option value={serverItem.value}>
                              {serverItem.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="col-span-12 md:col-span-7 mt-5">
                  <div>
                  <Chip label="Step 3 | เลือกแพคเกจ" color="primary" />
                    <div className="mb-2 mt-2">
                      <div className="w-full rounded-md">
                        <div className="grid w-full grid-cols-1 gap-4 2xl:grid-cols-2">
                          {Array.isArray(package_game) &&
                            package_game.map((packageItem) => (
                              <div
                                className={`w-full max-w-sm border rounded-xl ${
                                  packageItem.price === selectedPackage ? "selectedPackage" : "border-gray-900"
                                }`}
                                title={packageItem.price}
                                key={packageItem.price}
                                onClick={() => {
                                  setSelectedPackage(packageItem.price);
                                  setSelectedPrice(packageItem.price);
                                  setdescription(packageItem.description);
                                }}
                              >
                                <div class="px-5 pb-5 mt-5">
                                  <h5
                                    class="text-xl font-semibold tracking-tight text-white"
                                    dangerouslySetInnerHTML={{
                                      __html: packageItem.description,
                                    }}
                                  ></h5>
                                  <div class="flex items-center mt-2.5 mb-5">
                                    <span class="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-purple-400">
                                      <del>ราคา {packageItem.price} บาท</del>
                                    </span>
                                  </div>
                                  <div class="flex items-center justify-between mt-5">
                                    <span class="text-3xl font-bold text-white">
                                      ราคา {Math.floor(packageItem.price - (packageItem.price * percent) / 100)} บาท
                                    </span>
                                  </div>
                                  { packageItem.price === selectedPackage ? <><i className='fa-regular fa-circle-check mt-3'style={{fontSize: 20}}></i>&nbsp;เลือกแล้ว</> : ""}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Chip label="Step 4 | ชำระเงิน" color="primary" />
                <div className="w-full rounded-md p-6 shadow-md w-full border rounded-xl border-gray-900 mt-3 mb-2">
                  <div className="mb-4 grid grid-cols-2 gap-2">
                    <div className="font-bold">ข้อมูลกระเป๋าตัง</div>
                    <div className="truncate text-right font-bold">
                      {user.acc_mobile || "กรุณาเข้าสู่ระบบ"}
                    </div>
                    <div className="font-bold">ยอดเงินคงเหลือ </div>
                    <div className="text-right font-bold">
                      <p className="rounded badge !bg-yellow-100 !text-yellow-600">
                        {user.acc_credit || "0.00"} บาท
                      </p>
                    </div>
                    <div className="font-bold">เคดิตเงินคืนคงเหลือ</div>
                    <div className="text-right font-bold">
                      <p className="rounded badge !bg-red-100 !text-red-600">
                        {user.acc_point || "0.00"}{" "}
                        HCG
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full rounded-md p-6 shadow-md w-full border rounded-xl border-gray-900 mt-3 mb-2">
                  <div className="mb-4 grid grid-cols-2 gap-2">
                    <div className="font-bold">รายละเอียด</div>
                    <div className="truncate text-right font-bold">
                      {username || "ID"} {selectedServer}
                    </div>
                    <div className="font-bold">ราคาเต็ม</div>
                    <div className="text-right font-bold">
                      <p className="rounded badge !bg-yellow-100 !text-yellow-600">
                        {selectedPrice} บาท
                      </p>
                    </div>
                    <div className="font-bold">ส่วนลด</div>
                    <div className="text-right font-bold">
                      <p className="rounded badge !bg-red-100 !text-red-600">
                        {percent} %
                      </p>
                    </div>
                    <div className="font-bold">ยอดเงินที่ต้องชำระ</div>
                    <div className="text-right font-bold">
                      <p className="rounded badge !bg-purple-100 !text-purple-600">
                        {Math.floor(selectedPrice - (selectedPrice * percent / 100))} บาท
                      </p>
                    </div>
                    <div className="font-bold">ชำระเงินด้วย</div>
                    <div className="text-right font-bold">
                      <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5">
                        <option selected>กระเป๋าเงิน ({user.acc_credit || "0.00"} บาท)</option>
                        <option disabled value="US">เคดิตเงิน ({user.acc_point || "0.00"}{" "}HCG)</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center mt-2">
                      <input
                        id="checked-checkbox"
                        type="checkbox"
                        value=""
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 text-blue-600 rounded-lg focus:ring-blue-600 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
                      />
                      <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-300">
                        ยอมรับ <b>เงื่อนไขการให้บริการ</b>
                      </label>
                    </div>
                  <div className="w-full mt-2">
                    <Button
                      type="button"
                      onClick={handlePurchase}
                      disabled={loadingPay}
                      variant="contained"
                      className="w-full "
                    >
                       <><i class="bi bi-arrow-up-right-square-fill"></i>&nbsp;ดำเนินชำระเงิน</>
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <Dialog
            open={tos}
            onClose={() => setTOS(false)}
            className="backdrop"
          >
            <DialogTitle>เงื่อนไขการให้บริการ</DialogTitle>
            <DialogContent>
            <Alert severity="warning">
                  <span class="sr-only">Danger</span>
                  <div>
                    <span class="font-medium">เงื่อนไขการให้บริการ</span>
                    <ul class="mt-1.5 list-disc list-inside">
                      <li>
                        ถ้าหากลูกค้ากรอกเลข ID มาผิด
                        ทางเราจะไม่รับผิดชอบและไม่มีการคืนเงิน ทุกรณี
                      </li>
                      <li>
                        หลังจากดำเนินการสั่งซื้อสำเร็จ กรุณารอ 2 - 3 นาที
                        ยอดเงินจะเข้าเกม
                      </li>
                      <li>
                        จะไม่มีการยกเลิกรายการ และขอคืนเงินหรือเครดิต
                        หลังการทำรายการแล้ว ทุกรณี
                      </li>
                      <li>
                        หากมีข้อสงสัยสามารถ กดที่แชทด้านขวามือ หรือ ติดต่อ
                        เพจได้เลยครับ
                      </li>
                    </ul>
                  </div>
                </Alert>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setTOS(false);
                }}
                color="primary"
                autoFocus
              >
                ตกลง
              </Button>
            </DialogActions>
          </Dialog>
        </>
    </>
  );
};

export default Termgame;
