
const TOS = () => {

  return (
    <>
     <div class="container mx-auto p-8 min-h-screen container mx-auto px-4">
    <h1 class="text-3xl font-bold text-center mb-8 mt-24">ข้อกำหนดและเงื่อนไขการให้บริการ</h1>
    <div class="prose">
        {/* <p>เงื่อนไขการให้บริการนี้เป็นข้อตกลงระหว่างคุณหรือหน่วยงาน ซึ่งต่อไปนี้จะเรียกว่า "ผู้ใช้" และบริการ  <label className="text-purple-500 font-semibold ">เติมเกมสิ</label>.app ซึ่งต่อไปนี้จะเรียกว่า "บริการ" ผู้ใช้สามารถเติมเงินเข้าสู่แพลตฟอร์มเพื่อชำระค่าสินค้าและบริการจากร้านค้าตามเงื่อนไขการให้บริการ ผู้ใช้ควรอ่านและเข้าใจเงื่อนไขการให้บริการอย่างละเอียด ติดต่ออีเมลล์ด้านล่างได้</p> */}
        <h2 className="text-purple-500 text-lg font-semibold mt-5">1. ข้อกำหนดการใช้บริการทั่วไป</h2>
        <p>1.1 การใช้บริการของ <label className="text-purple-500 font-semibold "> <label className="text-purple-500 font-semibold ">เติมเกมสิ</label></label> แสดงถึงความยินยอมของผู้ใช้ที่ยอมรับข้อกำหนดและเงื่อนไขที่ระบุไว้ด้านล่างนี้ กรุณาอ่านและทำความเข้าใจข้อกำหนดและเงื่อนไขเหล่านี้ก่อนที่คุณจะเริ่มใช้บริการ</p>

        <h2 className="text-purple-500 text-lg font-semibold mt-5">2. ความเป็นส่วนตัว บัญชี และความปลอดภัย</h2>
        <p>2.1  <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> ใช้การเข้ารหัสข้อมูลเพื่อรักษาความเป็นส่วนตัวของข้อมูลผู้ใช้ทุกคนที่เกี่ยวข้องกับบัญชีผู้ใช้ รวมถึงข้อมูลบัญชีและข้อมูลการชำระเงิน</p>
        <p>2.2  <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> จะไม่เผยข้อมูลส่วนตัวของคุณให้แก่บุคคลภายนอกหรือองค์กรอื่น ๆ โดยไม่ได้รับอนุญาตจากคุณเอง</p>
        <p>2.3 คุณต้องรักษาความลับของบัญชีและรหัสผ่านของคุณและไม่ควรเปิดเผยข้อมูลเข้าถึงบัญชีของคุณให้แก่บุคคลอื่น</p>

        <h2 className="text-purple-500 text-lg font-semibold mt-5">3. เงื่อนไขการใช้บริการ</h2>
        <p>3.1 การใช้บริการของ <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> ต้องปฏิบัติตามกฎหมายและข้อกำหนดที่กำหนดไว้</p>
        <p>3.2 คุณยอมรับว่าจะไม่นำเสนอเนื้อหาที่ไม่เหมาะสมหรือละเมิดลิขสิทธิ์ สิทธิในทรัพย์สินทางปัญญา หรือกฎหมายอื่น ๆ</p>

        <h2 className="text-purple-500 text-lg font-semibold mt-5">4. การละเมิดเงื่อนไขการใช้บริการ</h2>
        <p>4.1 หากคุณละเมิดข้อกำหนดและเงื่อนไขของ <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> และ <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> สงวนสิทธิ์ในการระงับหรือยกเลิกบัญชีของคุณโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>

        <h2 className="text-purple-500 text-lg font-semibold mt-5">5. การชำระเงิน</h2>
        <p>5.1 การสั่งซื้อและการเติมเงินจะต้องชำระเงินตามราคาและเงื่อนไขที่ระบุไว้</p>
        <p>5.2  <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> ขอสงวนสิทธิ์ในการปรับปรุงราคาและเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>
        <p>5.3  <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> ขอสงวนสิทธิ์ในการคืนเงิน หากชำระเงินเข้าสู่ระบบแล้ว ไม่มีนโยบายทำเรื่องคืนเงินทุกกรณี</p>

        <h2 className="text-purple-500 text-lg font-semibold mt-5">6. การยกเลิกการสั่งซื้อสินค้า</h2>
        <p>6.1 หากมีการดำเนินการเติมเงินแล้ว คำสั่งซื้อจะไม่สามารถยกเลิกได้และขอเงินคืนได้ทุกรณี</p>
        <p>6.1 หากมีการดำเนินการสั่งซื้อรายการต่างๆๆ คำสั่งซื้อจะไม่สามารถยกเลิกได้และขอเงินคืนได้ทุกรณี</p>

        <h2 className="text-purple-500 text-lg font-semibold mt-5">7. นโยบายการคืนเงิน</h2>
    <p>7.1 ไม่มีนโยบายการคืนเงินหลังจากทำการเติมเงินแล้ว</p>
    <p>7.2 กรุณาตรวจสอบการทำธุรกรรมอย่างรอบคอบก่อนทำการเติมเงิน</p>

    <h2 className="text-purple-500 text-lg font-semibold mt-5">8. รายละเอียดการติดต่อ</h2>
    <p>8.1 สำหรับข้อสงสัยหรือข้อเสนอแนะเพิ่มเติม โปรดติดต่อ <label className="text-purple-500 font-semibold ">เติมเกมสิ</label> ทางอีเมล <label className="text-purple-500 font-semibold ">contact@termgamesi.app</label> หรือเพจ <label className="text-purple-500 font-semibold ">เติมเกมสิ.app</label></p>
</div>
</div>

    </>
  );
};

export default TOS;
