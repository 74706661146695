import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import { CircularProgress, Typography, Button, TextField, Grid, Paper, Box } from "@mui/material";

function ApplicationsDevCreate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const hzcToken = Cookies.get("HNAWNYToken");
  const [user, setUser] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}@me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        setLoading(false);
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
        setLoading(false);
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData(event.target);
      const formDataJSON = Object.fromEntries(formData.entries());

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}create_application`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(formDataJSON),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("Application created successfully");
        navigate("/app/applications/dev");
      } else {
        toast.error(data.message || "Failed to create application");
      }
    } catch (error) {
      console.error("Error creating application:", error);
      toast.error("Failed to create application");
    }
  };

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | GAME's API Create</title>
      </Helmet>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Grid container justifyContent="center" alignItems="center" minHeight="100vh">
          <Grid item xs={12} md={8} lg={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>GAME's API Create</Typography>
              <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: 3 }}>สร้าง เกม API</Typography>
              <Button variant="contained" onClick={() => navigate('/app/applications/dev')} sx={{ mb: 2 }}>ย้อนกลับ</Button>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="GAME's API Name"
                  variant="outlined"
                  name="applications_name"
                  sx={{ mb: 2 }}
                  required
                  placeholder="ชื่อแอพคุณชื่ออะไรละ ?"
                />
                <TextField
                  fullWidth
                  label="GAME's API Details"
                  variant="outlined"
                  name="applications_details"
                  sx={{ mb: 2 }}
                  required
                  placeholder="ลองบอกรายละเอียดของแอพสักนิดสิ"
                />
                <TextField
                  fullWidth
                  label="GAME's API Domain"
                  variant="outlined"
                  name="applications_hostname"
                  sx={{ mb: 2 }}
                  required
                  placeholder="โดมเมนของคุณอะไร localhost หรือป่าว"
                />
                <TextField
                  fullWidth
                  label="GAME's API whitelist IP"
                  variant="outlined"
                  name="applications_whitelistIP"
                  sx={{ mb: 2 }}
                  required
                  placeholder="ไม่อยากจำกัด IP ก็แค่ใช้ * แต่ถ้า IP เยอะก็แค่ใช้ (,)"
                />
                <Typography variant="body2" sx={{ mb: 2 }}>EX. 192.168.1.2 , 185.2.5.22</Typography>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: 'primary.main', color: 'white', '&:hover': { bgcolor: 'primary.dark' }, mb: 2 }}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    <>ดำเนินการสร้าง</>
                  )}
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ApplicationsDevCreate;
