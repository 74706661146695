import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
// import 'aos/dist/aos.css';
import 'flowbite';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './components/Footer';
import MenuAppBar from './components/MenuAppBar';
import { Toaster } from 'react-hot-toast';

import Login from './pages/auth/Login';
import ShopApi from './pages/auth/ShopApi';
import ResetPassword from './pages/auth/ResetPassword';
import Register from './pages/auth/Register';
import Profile from './pages/auth/Profile';
import Settings from './pages/auth/Setting';
import Error from './pages/404';
import Home from './pages/Home';
import Payment from './pages/payment/index';
import TrueWalletP2P from './pages/payment/TrueWalletP2P';
import PaymentDetails from './pages/payment/details';


import Cashcard from './pages/Cashcard';
import CashcardTopup from './pages/CashcardTopup';
import Termgame from './pages/Termgame';
import PurchaseDetails from './pages/Purchase_details';
import Tracking from './pages/Tracking';
import TermgameHome from './pages/TermgameHome';

import Transactions  from './pages/auth/Transactions';

import ApplicationsDevIndex  from './pages/ApplicationsDev/Index';
import ApplicationsDevCreate  from './pages/ApplicationsDev/Create';
import ApplicationsDevInfo  from './pages/ApplicationsDev/Info';
import ApplicationsDevEdit  from './pages/ApplicationsDev/Edit';

import Streaming  from './pages/Services/Streaming';
import TOS  from './pages/Tos';

import getLPTheme from './getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  AOS.init({
    duration: 400,
    easing: 'ease-in-out',
    offset: 50,
    once: true
  });

  const LPtheme = createTheme(getLPTheme("dark"));
 

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={true} />
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        <Router>
          <>
            
            <MenuAppBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/register" element={<Register />} />
              <Route path="/auth/resetpassword" element={<ResetPassword />} />
              <Route path="/app/" element={<Home />} />
              <Route path="/app/home" element={<Home />} />
              <Route path="/app/profile" element={<Profile />} />
              <Route path="/app/setting" element={<Settings />} />
              <Route path="/app/payment" element={<Payment />} />
              <Route path="/app/profile/payment/callback/truewallet-p2p/:id" element={<TrueWalletP2P />} />
              <Route path="/app/profile/payment/callback/details/:id" element={<PaymentDetails />} />
              <Route path="/app/shop/api" element={<ShopApi />} />
              <Route path="/app/transactions" element={<Transactions />} />
              <Route path="/app/tracking" element={<Tracking />} />
              <Route path="/shop/termgame/:id" element={<Termgame />} />
              <Route path="/app/profile/purchase/callback/details/:id" element={<PurchaseDetails />} />
              <Route path="/shop/termgame" element={<TermgameHome />} />
              <Route path="/shop/cashcard/:id" element={<Cashcard />} />
              <Route path="/shop/cashcard/" element={<CashcardTopup />} />
              <Route path="/shop/cashcard-topup" element={<CashcardTopup />} />
              <Route path="/app/applications/dev/" element={<ApplicationsDevIndex />} />
              <Route path="/app/applications/dev/create" element={<ApplicationsDevCreate />} />
              <Route path="/app/applications/dev/my/:id" element={<ApplicationsDevInfo />} />
              <Route path="/app/applications/dev/edit/:id" element={<ApplicationsDevEdit />} />
              <Route path="/app/shop/services/streaming" element={<Streaming />} />
              <Route path="/app/terms-of-use" element={<TOS />} />
              <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
          </>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
