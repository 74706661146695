import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { Turnstile } from '@marsidev/react-turnstile';

function API() {
  const navigate = useNavigate();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [password, setPassword] = useState("");
  const [api, setApi] = useState("");
  const [Cresponse, setTurnstileToken] = useState();
  const [user, setUser] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        setUser(data.user);
      } else {
        setLoading(false);
        toast.error(data.message);
        navigate('/auth/login');
        Cookies.remove('HZCSToken');
      }
    } catch (error) {
      toast.error("Error fetching data:", error);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      Cookies.remove('HNAWNYToken');
      toast.success("Successfully logged out");
      navigate(`/auth/login`);
    }, 2000);
  };

  const authShowApi = async () => {
    const requestData = { password, Cresponse };
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}showApi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${hzcToken}`,
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    if (data.status === 200) {
      setLoading(false);
      setShow(false);
      setApi(data.apitoken);
      fetchData();
      toast.success(data.message);
    } else {
      setLoading(false);
      toast.error(data.message);
    }
  }

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | API SHOP</title>
      </Helmet>
      {show ? (
        <Container maxWidth="sm">
          <Box
            mt={24}
            p={4}
            boxShadow={5}
            bgcolor="background.paper"
            borderRadius={8}
          >
            <Typography variant="h4" align="center" gutterBottom>
              AUTH กรุณาใส่รหัสผ่านเพื่อเข้าถึงหน้านี้
            </Typography>
            <Box my={4}>
            <Typography variant="caption">รหัสผ่าน</Typography>
              <TextField
                type="password"
                fullWidth
                variant="outlined"
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Turnstile
                siteKey={process.env.REACT_APP_SITEKEY}
                options={{
                  action: 'submit-form',
                  theme: 'auto',
                  size: 'invisible',
                }}
                onSuccess={setTurnstileToken}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={authShowApi}
                disabled={loading}
                sx={{ mt: 3 }}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  <><i className="bi bi-shield-lock"></i>&nbsp; เข้าสู่ระบบระบบจัดการ Client ID</>
                )}
              </Button>
            </Box>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="sm">
          <Box
            mt={12}
            p={4}
            boxShadow={5}
            bgcolor="background.paper"
            borderRadius={8}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Client ID ของคุณ
            </Typography>
            <Box my={4}>
              <Typography variant="h5">{user.acc_mobile}</Typography>
              <Typography variant="body1" color="textSecondary">
                เป็นสมาชิกเมื่อ: {user.acc_date}
              </Typography>
            </Box>
{/* 
            <Divider /> */}

            <Box my={4}>
              <Typography variant="h5">Client ID ของคุณ</Typography>
              <TextField
                type="text"
                disabled
                fullWidth
                variant="outlined"
                margin="normal"
                value={api}
              />
            </Box>

            {/* <Divider /> */}

            <Box mt={4}>
              <Typography variant="h5" align="center" gutterBottom>
                เพิ่มเติม
              </Typography>
              <Box mt={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/help")}
                  sx={{ mb: 2 }}
                >
                  <i className="bi bi-person-raised-hand"></i> ติดต่อช่วยศูนย์ช่วยเหลือ
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={logout}
                  disabled={loading}
                  sx={{
                    bgcolor: "#f2383b",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#d42b2e",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    <><i class="bi bi-door-closed"></i> ออกจากระบบ</>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
}

export default API;
