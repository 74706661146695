import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import TextField from '@mui/material/TextField';

const Home = () => {
  const navigate = useNavigate();
  const [game, setGame] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // เพิ่ม state สำหรับเก็บค่าการค้นหา
  const [searchResults, setSearchResults] = useState([]); // เพิ่ม state เก็บผลลัพธ์ของการค้นหา

  
  const GetGame = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}getGame`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setGame(data.data);
        // หลังจากโหลดข้อมูลเกมส์เสร็จ ก็อัพเดตผลลัพธ์การค้นหาด้วยข้อมูลเกมส์
        setSearchResults(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      // handle fetch error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetGame();
  }, []);

  // ฟังก์ชันสำหรับค้นหา
  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    // กรองข้อมูลเกมส์ที่ตรงกับคำค้นหา
    const filteredGames = game.filter((gameItem) =>
      gameItem.company_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredGames);
  };

  return (
    <>
    <Helmet>
        <title>เติมเกมสิ | Termgame</title>
      </Helmet>
        <>
          <div
            className="min-h-screen container mx-auto px-4 mt-28"
            data-aos="zoom-out-up"
          >
            <div class="flex place-items-center space-x-3 mt-12" id="game">
              <p class="mb-3 text-xl font-bold text-gray-100">เติมเกมออนไลน์</p>
            </div>
            <div class="mb-6">
              <label
                for="default-input"
                class="block mb-2 text-sm font-medium text-white"
              >
                ค้นหาเกมที่ต้องการ
              </label>
              <TextField
                type="text"
                id="default-input"
                placeholder="อยากเติมเกมอะไร หาเลย"
                variant="outlined"
                value={searchTerm} // ผูกค่า input กับ state ของการค้นหา
                onChange={handleSearch} // เมื่อมีการเปลี่ยนแปลงใน input ให้เรียก handleSearch
              />
            </div>
            <div class="grid grid-cols-2 md:grid-cols-6 gap-y-5 container mx-auto">
                {Array.isArray(searchResults) && searchResults.length > 0 ? (
                    searchResults.map((gameItem) => (
                    <div
                        className={`gamer`}
                        title={gameItem.company_id}
                        key={gameItem.company_id}
                        onClick={() => navigate(`/shop/termgame/${gameItem.company_id}`)}
                    >
                        <div class="px-5 pb-5 mt-5 bg-opacity-80">
                        <div class="flex items-center justify-between mt-5">
                            <div class="relative overflow-hidden rounded-md aspect-square">
                            <div class="z-[1] text-graySecondary font-medium absolute top-0 right-0 bg-main-400 rounded-tl-md rounded-br-md">
                                <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                                ส่วนลด : {gameItem.percentShow} %
                                </p>
                            </div>
                            <div class="z-[1] text-graySecondary font-medium absolute bottom-0 left-0 bg-main-400 rounded-tl-md rounded-br-md">
                                <p class="text-xs px-2 text-white bg-gradient-to-br from-purple-900 to-orange-400 to-red-600 text-xs font-medium px-2.5 py-0.5 rounded">
                                {gameItem.company_name}
                                </p>
                            </div>
                            <img
                                src={`/assets/game/product/${gameItem.company_id}.png`}
                                class="w-full object-cover h-full"
                                alt={gameItem.company_id}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    ))
                ) : (
                    <>
                        <div className="text-center text-gray-50 text-2xl py-8">
                        <i class="bi bi-emoji-frown-fill"></i> ไม่พบสินค้าที่ต้องการ
                        </div>
                    </>
                )}
            </div>

          </div>
        </>
    </>
  );
};

export default Home;
