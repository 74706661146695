import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import { Spinner } from "flowbite-react";

function Payment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const hzcToken = Cookies.get("HNAWNYToken");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [dataWebhook, setdataWebhook] = useState("");
  const [expire, setExpire] = useState();

  const fetchData = async () => {
    localStorage.setItem('loading', 'true');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}@me`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hzcToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === 200) {
        localStorage.setItem('loading', 'false');
        setUser(data.user);
      } else if (data.status === 403 || data.status === 401) {
        localStorage.setItem('loading', 'false');
        toast.error(data.message);
        navigate("/auth/login");
        Cookies.remove("HZCSToken");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    sendDataToAPI();
    fetchData();
    const intervalId = setInterval(sendDataToAPI, 2000);
    setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const sendDataToAPI = async () => {
    const requestData2 = {
      payment_id: id,
      step: "getStatement"
    };
  
    const response2 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}truewalletWebhook`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${hzcToken}`,
      },
      body: JSON.stringify(requestData2),
    });
  
    const data2 = await response2.json();
    if (data2.status === 200) {
      setLoading(true)
      fetchData();
      setdataWebhook("");
      toast.success(data2.message);
    } else {
      setLoading(false)
        if(data2.act === "wait"){
            setdataWebhook(data2.data);
        } else if (data2.act === "expire") {
            setExpire(data2.act);
        } else {
            navigate("/app/transactions");
        }
    }
  };

  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const formattedTime = calculateTimeRemaining(dataWebhook.date_expire); // แทนด้วย dataWebhook.date_expire หรือตัวแปรที่เก็บวันหมดอายุ

      setRemainingTime(formattedTime);
    }, 1000);

    // เมื่อ unmount component ให้ทำคำสั่ง clearInterval เพื่อเลิกการเรียกใช้งานฟังก์ชันทุกๆ 1 วินาที
    return () => clearInterval(intervalId);
  }, [dataWebhook]); // ใช้ useEffect โดยไม่มี dependencies เพื่อให้เรียกใช้งานฟังก์ชันเพียงครั้งเดียวเมื่อ component ถูก mount


  function calculateTimeRemaining(expireDateString) {
    // กำหนดวันหมดอายุ
    const expireDate = new Date(expireDateString);
  
    // วันที่และเวลาปัจจุบัน
    const currentDate = new Date();
  
    // คำนวณเวลาที่เหลือ
    const timeDifference = expireDate.getTime() - currentDate.getTime();
    const remainingMinutes = Math.floor(timeDifference / (1000 * 60));
    const remainingSeconds = Math.floor((timeDifference / 1000) % 60);
  
    // สร้างข้อความแบบเรียวไทม์
    const formattedTime = `${remainingMinutes} นาที ${remainingSeconds} วินาที`;
  
    return formattedTime;
  }

  const Cancel = async () => {
    const requestData = { payment_id: id, t: "wallet_webhook" };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}cancelPayment`, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json' ,
            Authorization: `Bearer ${hzcToken}`,
        },
        body: JSON.stringify(requestData),
    });

    const data = await response.json();
    if (data.status === 200) {
      toast.success(data.message);
        navigate(`/app/transactions`);
    } else {
        toast.error(data.message);
    }
  }

  return (
    <>
      <Helmet>
        <title>เติมเกมสิ | Payment TrueWallet QR</title>
      </Helmet>

        <div className="min-h-screen flex justify-center items-center p-8 shadow-md">
           <div class="relative p-4 ounded-lg sm:p-5 ">

            <div className="space-y-4">
              <div id="wallet_webhook">
                <div className="max-w-sm p-6 border rounded-lg shadow">
                <div className="divider">
                  <h2 className="text-lg font-semibold mt-3">
                  ชำระเงินด้วยด้วยทรูวอลเล็ต QR!
                  </h2>
                </div>
                  {!expire && (
                  <>
                    <h1 className="mb-2 text-sm font-semibold tracking-tight text-red-400">Qr Code สามารถสแกนจ่ายได้เพียงครั้งเดียวเท่านั้น โปรดอย่าสแกนจ่ายซ้ำ</h1>
                    <div class="bg-white relative pointer-events-none aspect-square w-2/3 mx-auto rounded overflow-hidden">
                      <span class="text-base sm:text-lg lg:text-2xl -rotate-45 text-red-500/80 font-bold absolute inset-0 flex justify-center items-center text-center" >สำหรับเติมเครดิตบนเว็บไซต์<br />Termgamesi.app เท่านั้น!!!</span> 
                      <img class="w-full" src={dataWebhook.dataImg} alt={dataWebhook.code} />
                    </div>
                    <h1 className="mb-2 text-2xl font-semibold tracking-tight  text-center mt-3">จำนวนเงิน {dataWebhook.amount}.00 บาท</h1>
                      <h2 className="text-sm font-semibold text-center">
                       สแกนไม่ได้ใช่ไหม
                      </h2>                
                    <h1 className="mb-2 text-sm font-semibold tracking-tight text-center">โอนที่วอเลท ID : Hnaw_Shop</h1>
                    <h1 className="mb-2 text-sm font-semibold tracking-tight text-center">ข้อความ : {dataWebhook.code}</h1>
                    <hr className="mt-2" />
                    <h1 className="mb-2 mt-3 text-3xl font-semibold tracking-tight text-center">{remainingTime}</h1>
                    <h1 className="mb-2 text-sm font-semibold tracking-tight text-center ">หลังโอนเงินกรุณารอ 5 - 15 วิ ระบบจะทำการเพิ่มเครดิตเข้าอัตโนมัติ</h1>
                    <h1 className="mb-2 text-sm font-semibold tracking-tight text-center text-red-600">ใช้สำหรับเบอร์ที่ลงทะเบียน เติมเกมสิ และ สำหรับทรูมันนี่วอลเล็ตเท่านั้น!</h1>
                    <button type="button"  disabled={loading} onClick={Cancel} class="w-full focus:outline-none text-white bg-purple-400 hover:bg-purple-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-purple-900">ยกเลิกรายการนี้</button>
                  </>
                )}
                {expire && (
                  <>
                    <img src="/assets/qr-error.png" className="text-sm rounded-lg block w-full p-2.5 bg-purple-700 border-purple-600 placeholder-purple-400 focus:ring-blue-500 focus:border-blue-500" alt="" />
                    <h1 className="mb-2 text-2xl font-semibold tracking-tight  text-center mt-5 text-red-500">คิวอาร์โค้ดนี้หมดอายุแล้ว</h1>
                    <h1 className="mb-2 text-2xl font-semibold tracking-tight  text-center mt-2 text-red-500">โปรดสร้างรายจากชำระเงินใหม่</h1>

                  </>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Payment;
